import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import { LoggedContext } from "./LoggedContext";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { analyticsManager } from "..";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Checkbox, IconButton, TextField } from "@mui/material";
import MaterialButton from "../maker/component/MaterialButton";
import './Logged.css';
import { addDoc, arrayUnion, collection, doc, getDoc, getFirestore, runTransaction, updateDoc } from "firebase/firestore";
import { set } from "firebase/database";

/**
 * Notes:
 *  - impossible to remove a dish entry or a description
 */
function MenuEditor(props) {
    const windowDimensions = useContext(LoggedContext).get("windowDimensions");    
    const setActivePage = useContext(LoggedContext).get("setActivePage");
    const uid = useContext(LoggedContext).get("uid");
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const { t } = useTranslation();  
    const [menu, setMenu] = useState(null);
    const [page, setPage] = useState({});
    const [entriesNewLineNb, setEntriesNewLineNb] = useState([]);
    const [notFormattedDishEntries, setNotFormattedDishEntries] = useState([]);
    const [notFormattedPriceEntries, setNotFormattedPriceEntries] = useState([]);
    const [permitAllergensAndNutrients, setPermitAllergensAndNutrients] = useState(false);

    async function createMenuAndEntry(){
        const db = getFirestore();    
        const menuId = document.getElementById("menu-id").value;
        let menuDoc = null;
        let menuRef = null;
        await runTransaction(db, async (transaction) => {
            if (menuId !== ""){                
                menuRef = doc(db, "menus", menuId);
                menuDoc = await transaction.get(menuRef);
            }
            if (menuId === "" || !menuDoc.exists()) {
                menuRef = doc(collection(db, "menus"));                
                await transaction.set(menuRef, {uid: uid, menuPages: []});                
            }
        }).then(
            () => {
                if (menuDoc != null){
                    // retrieval
                    const menu = menuDoc.data();
                    menuRef.id = menuDoc.id;                     
                    setMenu(menu);                    
                }
                else {
                    // creation
                    const menu = {id: menuRef.id, menuPages: []};                    
                    setMenu(menu);
                }
            }
        );
    }

    async function loadMenu(){
        const db = getFirestore();    
        const menuId = document.getElementById("menu-id").value;
        let menuDoc = null;
        let menuRef = null;
        if (menuId !== ""){            
            menuRef = doc(db, "menus", menuId);
            menuDoc = await getDoc(menuRef);            
            setMenu({id: menuId, menuPages: menuDoc.data().menuPages});                
        }
    }

    async function updateOrder(){
        if (menu == null) return;

        const db = getFirestore(); 
        await updateDoc(doc(db, "menus", menu.id), {
            menuPages: menu.menuPages
        });
    }

    async function loadPage(){
        const db = getFirestore();    
        const pageId = document.getElementById("page-id").value;
        if (pageId !== ""){    
            const pageRef = doc(db, "menu_pages", pageId);
            const pageDoc = await getDoc(pageRef);
            const page = pageDoc.data();
            page.id = pageDoc.id;
            setPage(page);
        }
        else {
            setPage({});
        }
    }

    async function savePage(){
        const pageId = document.getElementById("page-id").value;
        const menuId = document.getElementById("menu-id").value;
        //const pageEntries = formToPage().pageEntries;
        if (pageId === ""){
            const db = getFirestore();            
            
            await addDoc(collection(db, "menu_pages"), {
                uid: uid, 
                menuId: menuId,
                pageType: page.pageType ? page.pageType : {},
                pageEntries: page.pageEntries ? page.pageEntries : [],
                pageImgUrl: page.pageImgUrl ? page.pageImgUrl : [],
                pageBackground: page.pageBackground ? page.pageBackground : {},
            }).then((docRef)=>{
                updateDoc(doc(db, "menus", menuId), {
                    menuPages: arrayUnion(docRef.id)
                });
                return docRef.id;
            }).then((newPageId)=>{       
                const newMenu = Object.assign({}, menu);         
                newMenu.menuPages.push(newPageId);
                setMenu(newMenu);

                const newPage = Object.assign({}, page);                
                newPage.id = newPageId;
                setPage(newPage);                
            });
        }
        else {
            const db = getFirestore();
            await updateDoc(doc(db, "menu_pages", pageId), {
                uid: uid, 
                menuId: menuId,
                pageType: page.pageType ? page.pageType : {},
                pageEntries: page.pageEntries ? page.pageEntries : [],
                pageImgUrl: page.pageImgUrl ? page.pageImgUrl : [],
                pageBackground: page.pageBackground ? page.pageBackground : {},
            });
        }
    }

    async function translatePage(){
        
        const newPage = Object.assign({}, page);
        //console.log(JSON.stringify(newPage.pageType));
        if (newPage.pageType && newPage.pageType.fr && newPage.pageType.fr.trim() !== "" && (!newPage.pageType.en || newPage.pageType.en.trim() === "")){                
            newPage.pageType.en = await translateText(newPage.pageType.fr);
        }

        for (let i = 0; i < newPage.pageEntries.length; i++) {   
            if (newPage.pageEntries[i].entryType === "startingNote" || newPage.pageEntries[i].entryType === "endingNote"){
                if (newPage.pageEntries[i].text && newPage.pageEntries[i].text.fr && newPage.pageEntries[i].text.fr.trim() !== "" && (!newPage.pageEntries[i].text.en || newPage.pageEntries[i].text.en.trim() === "")){
                    newPage.pageEntries[i].text.en = await translateText(newPage.pageEntries[i].text.fr);
                }
            }
            else {

                if (newPage.pageEntries[i].entriesTitle && newPage.pageEntries[i].entriesTitle.fr && newPage.pageEntries[i].entriesTitle.fr.trim() !== "" && (!newPage.pageEntries[i].entriesTitle.en || newPage.pageEntries[i].entriesTitle.en.trim() === "")){
                    newPage.pageEntries[i].entriesTitle.en = await translateText(newPage.pageEntries[i].entriesTitle.fr);
                }

                if (newPage.pageEntries[i].startingNote && newPage.pageEntries[i].startingNote.fr && newPage.pageEntries[i].startingNote.fr.trim() !== "" && (!newPage.pageEntries[i].startingNote.en || newPage.pageEntries[i].startingNote.en.trim() === "")){
                    newPage.pageEntries[i].startingNote.en = await translateText(newPage.pageEntries[i].startingNote.fr);
                }                        

                if (newPage.pageEntries[i].endingNote && newPage.pageEntries[i].endingNote.fr && newPage.pageEntries[i].endingNote.fr.trim() !== "" && (!newPage.pageEntries[i].endingNote.en || newPage.pageEntries[i].endingNote.en.trim() === "")){
                    newPage.pageEntries[i].endingNote.en = await translateText(newPage.pageEntries[i].endingNote.fr);
                } 
                
                if (newPage.pageEntries[i].entries){
                    for (let j = 0; j < newPage.pageEntries[i].entries.length; j++) { 
                        //console.log(JSON.stringify(newPage.pageEntries[i].entries[j]));  
                        if (newPage.pageEntries[i].entries[j].entryTitle && newPage.pageEntries[i].entries[j].entryTitle.fr && newPage.pageEntries[i].entries[j].entryTitle.fr.trim() !== "" && (!newPage.pageEntries[i].entries[j].entryTitle.en || newPage.pageEntries[i].entries[j].entryTitle.en.trim() === "")){
                            newPage.pageEntries[i].entries[j].entryTitle.en = await translateText(newPage.pageEntries[i].entries[j].entryTitle.fr);
                        }
                        if (newPage.pageEntries[i].entries[j].entrySubtitle && newPage.pageEntries[i].entries[j].entrySubtitle.fr && newPage.pageEntries[i].entries[j].entrySubtitle.fr.trim() !== "" && (!newPage.pageEntries[i].entries[j].entrySubtitle.en || newPage.pageEntries[i].entries[j].entrySubtitle.en.trim() === "")){
                            newPage.pageEntries[i].entries[j].entrySubtitle.en = await translateText(newPage.pageEntries[i].entries[j].entrySubtitle.fr);
                        }                        
                        if (newPage.pageEntries[i].entries[j].nutritionalValue && newPage.pageEntries[i].entries[j].nutritionalValue.fr && newPage.pageEntries[i].entries[j].nutritionalValue.fr.trim() !== "" && (!newPage.pageEntries[i].entries[j].nutritionalValue.en || newPage.pageEntries[i].entries[j].nutritionalValue.en.trim() === "")){
                            newPage.pageEntries[i].entries[j].nutritionalValue.en = await translateText(newPage.pageEntries[i].entries[j].nutritionalValue.fr);
                        }

                        if (newPage.pageEntries[i].entries[j].entryPrice && newPage.pageEntries[i].entries[j].entryPrice.fr){
                            for (let k = 0; k < newPage.pageEntries[i].entries[j].entryPrice.fr.length; k++) { 
                                if (!newPage.pageEntries[i].entries[j].entryPrice.en){
                                    newPage.pageEntries[i].entries[j].entryPrice.en = [];
                                }
                                if (newPage.pageEntries[i].entries[j].entryPrice.fr[k].match(/[a-zA-Z]+/))
                                    newPage.pageEntries[i].entries[j].entryPrice.en[k] = await translateText(newPage.pageEntries[i].entries[j].entryPrice.fr[k]);
                                else
                                    newPage.pageEntries[i].entries[j].entryPrice.en[k] = newPage.pageEntries[i].entries[j].entryPrice.fr[k];
                            }
                        }
                    }   
                }            
                
            }
        }

        setPage(newPage);    
    } 
    
    async function translateText(text){
        let textTranslated = null;
        await fetch("https://translation.googleapis.com/language/translate/v2", {
            method: "POST",
            body: JSON.stringify({                              
              q: text,
              target: "en",
              format: "text",
              source: "fr",
              model: "base",
              key: process.env.REACT_APP_GOOGLE_API_KEY,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": 'Bearer ' + document.getElementById("auth-token").value,
                "x-goog-user-project": process.env.REACT_APP_FIREBASE_PROJECT_ID, 
            }
          })
          .then ((response) => response.json())
          .then((data) => {console.log(data);textTranslated = data.data.translations[0].translatedText;});

        return textTranslated;
    }

    function returnEntries(pageEntries){
        if (pageEntries == null) return "";

        function textWithMoreThanXSuccessiveLinesWithText(text, xSuccessive){
            let result = false;
            const lines = text.split("\n");
            let successiveLinesWithText = 0;
            for (let i = 0; i < lines.length;i++){
                if (lines[i].trim() !== "") {
                    successiveLinesWithText++;
                    if (successiveLinesWithText > xSuccessive){
                        result = true;
                        break;
                    }
                }                
                else {
                    successiveLinesWithText = 0;
                }
            }

            return result;
        }

        function tableToTextDishesFr(entries, entriesFormIdx){ 

            if (notFormattedDishEntries[entriesFormIdx]){
                return notFormattedDishEntries[entriesFormIdx];
            }

            let newLineToAdd = 0;
            //alert(JSON.stringify(entries))
            // restoring lines entered at the end of the text
            
            if (entriesNewLineNb[entriesFormIdx]){
                newLineToAdd = entriesNewLineNb[entriesFormIdx];
            }            

            let dishes = "";
            for (let i = 0; i < entries.length; i++) {
                if (!entries[i].separator){
                    dishes += entries[i].entryTitle.fr;
                    if (entries[i].entrySubtitle){
                        dishes += "\n" + entries[i].entrySubtitle.fr;
                    }
                    if (entries[i].allergens){
                        dishes += "\n" + entries[i].allergens;
                    }
                    if (entries[i].nutritionalValue){
                        dishes += "\n" + entries[i].nutritionalValue.fr;
                    }
                }
                else {
                    dishes += "-";
                }
                if (i < entries.length - 1)
                    dishes += "\n\n";                
            }
            
            //console.log("add " + newLineToAdd + " new lines");
            for (let i = 0; i < newLineToAdd; i++) {
                dishes += "\n";
            }
            
            return dishes;
        }        

        function textToTableDishesFr(text, entriesFr, entriesFormIdx){              
            // if text is not formatted (eg following a paste), do not set page entries
            if (textWithMoreThanXSuccessiveLinesWithText(text, 2) && !permitAllergensAndNutrients){
                const newNotFormattedDishEntries = Object.assign({}, notFormattedDishEntries);
                newNotFormattedDishEntries[entriesFormIdx] = text;
                setNotFormattedDishEntries(newNotFormattedDishEntries);
                return [];
            }  
            else {
                const newNotFormattedDishEntries = Object.assign({}, notFormattedDishEntries);
                newNotFormattedDishEntries[entriesFormIdx] = null;
                setNotFormattedDishEntries(newNotFormattedDishEntries);
            }        
            if (text === "") return [];
            const entries = [];            
            const dishes = text.split("\n\n");            
            for (let i = 0; i < dishes.length; i++) {   
                if (dishes[i] === "") continue;
                
                const dish = dishes[i].split("\n");                
                let entry = {};
                // TODO delete, risk of copying data from another dish ???
                if (entriesFr && i < entriesFr.length){
                    entry = entriesFr[i];
                }
                entry.entryId = i;
                if (dish[0] !== "-"){
                    if (entry.separator){
                        delete entry.separator;
                    }
                    if (entry.entryTitle)
                        entry.entryTitle.fr = dish[0];                
                    else
                        entry.entryTitle = {"fr": dish[0]};

                    if (dish.length > 1 && dish[1] !== ""){
                        if (entry.entrySubtitle)
                            entry.entrySubtitle.fr = dish[1];
                        else
                            entry.entrySubtitle = {"fr": dish[1]};
                    }

                    if (dish.length > 2 && dish[2] !== ""){                        
                        entry.allergens = dish[2];                        
                    }

                    if (dish.length > 3 && dish[3] !== ""){
                        if (entry.nutritionalValue)
                            entry.nutritionalValue.fr = dish[3];
                        else
                            entry.nutritionalValue = {"fr": dish[3]};
                    }
                }
                else {
                    entry.separator = true;
                    if (entry.entryTitle)
                        delete entry.entryTitle;
                    if (entry.entrySubtitle)
                        delete entry.entrySubtitle;
                    if (entry.allergens)
                        delete entry.allergens;
                    if (entry.nutritionalValue)
                        delete entry.nutritionalValue;
                }
                entries.push(entry);
            }

            // to remember lines entered at the end
            const lines = text.split("\n");
            let newLineNb = 0;
            if (lines[lines.length - 1] === ""){
                newLineNb++;
                if (lines.length > 1 && lines[lines.length - 2] === ""){
                    newLineNb++;
                }
            }                        
            const newEntriesNewLineNb = Object.assign({}, entriesNewLineNb);
            newEntriesNewLineNb[entriesFormIdx] = newLineNb;
            setEntriesNewLineNb(newEntriesNewLineNb);
            //console.log("set newLineNb " + newLineNb);

            return entries;            
        }

        function tableToTextDishesEn(entries){            
            let dishes = "";
            for (let i = 0; i < entries.length; i++) {
                if (!entries[i].separator){
                    if (entries[i].entryTitle.en){
                        dishes += entries[i].entryTitle.en;
                    }
                    else {
                        continue;
                    }
                    if (entries[i].entrySubtitle){
                        if (entries[i].entrySubtitle.en){
                            dishes += "\n" + entries[i].entrySubtitle.en;
                        }
                        else {
                            dishes += "\n";
                        }
                    }
                    if (entries[i].allergens){
                        if (entries[i].allergens){
                            dishes += "\n" + entries[i].allergens;
                        }
                        else {
                            dishes += "\n";
                        }
                    }
                    if (entries[i].nutritionalValue){
                        if (entries[i].nutritionalValue.en){
                            dishes += "\n" + entries[i].nutritionalValue.en;
                        }
                        else {
                            dishes += "\n";
                        }
                    }
                }
                else {
                    dishes += "-";
                }    
                if (i < entries.length - 1)
                    dishes += "\n\n";                
            }

            return dishes;
        }

        function textToTableDishesEn(text, entriesFr){
            const dishes = text.split("\n\n");
            for (let i = 0; i < entriesFr.length; i++) {
                if (i >= dishes.length) continue;

                const dish = dishes[i].split("\n");
                const entry = entriesFr[i];              
                if (dish[0] !== "-"){
                    entry.entryTitle.en = dish[0];
                    if (dish.length > 1){
                        entry.entrySubtitle.en = dish[1];
                    }
                    if (dish.length > 2){
                        entry.allergens = dish[2];
                    }
                    if (dish.length > 3){
                        entry.nutritionalValue.en = dish[3];
                    }
                }
            }            

            return entriesFr;          
        }

        function tableToTextPrices(entries, entriesFormIdx){
            if (notFormattedPriceEntries[entriesFormIdx]){
                return notFormattedPriceEntries[entriesFormIdx];
            }

            let prices = "";
            for (let i = 0; i < entries.length; i++) {
                if (entries[i].entryPrice && entries[i].entryPrice.fr){
                    prices += entries[i].entryPrice.fr.join("_");    
                    if (i < entries.length - 1)            
                        prices += "\n\n";     
                }
                else if (entries[i].entryPrice && entries[i].entryPrice[0]){                    
                    prices += entries[i].entryPrice.join("_");    
                    if (i < entries.length - 1)            
                        prices += "\n\n"; 
                }
            }
            return prices;
        }

        function textToTablePrices(text, entriesFr, entriesFormIdx){
            // if text is not formatted (eg following a paste), do not set page entries
            const doNotAddPrice = textWithMoreThanXSuccessiveLinesWithText(text, 1);
            if (doNotAddPrice){
                const newNotFormattedPriceEntries = Object.assign({}, notFormattedPriceEntries);
                newNotFormattedPriceEntries[entriesFormIdx] = text;
                setNotFormattedPriceEntries(newNotFormattedPriceEntries);                
            }  
            else {
                const newNotFormattedPriceEntries = Object.assign({}, notFormattedPriceEntries);
                newNotFormattedPriceEntries[entriesFormIdx] = null;
                setNotFormattedPriceEntries(newNotFormattedPriceEntries);
            } 

            if (! doNotAddPrice){
                const prices = text.split("\n\n");
                for (let i = 0; i < entriesFr.length; i++) {
                    if (i >= prices.length) continue;        
                    const pricesFromLine = prices[i].split("_");        
                    entriesFr[i].entryPrice = {fr: pricesFromLine};
                }
            }

            return entriesFr;
        }

        function tableToTextPricesEn(entries, entriesFormIdx){
            if (notFormattedPriceEntries[entriesFormIdx]){
                return notFormattedPriceEntries[entriesFormIdx];
            }

            let prices = "";
            for (let i = 0; i < entries.length; i++) {
                if (entries[i].entryPrice && entries[i].entryPrice.en){
                    prices += entries[i].entryPrice.en.join("_");    
                    if (i < entries.length - 1)            
                        prices += "\n\n";     
                }           
            }
            return prices;
        }

        function textToTablePricesEn(text, entriesFr, entriesFormIdx){
            // if text is not formatted (eg following a paste), do not set page entries
            const doNotAddPrice = textWithMoreThanXSuccessiveLinesWithText(text, 1);
            if (doNotAddPrice){
                const newNotFormattedPriceEntries = Object.assign({}, notFormattedPriceEntries);
                newNotFormattedPriceEntries[entriesFormIdx] = text;
                setNotFormattedPriceEntries(newNotFormattedPriceEntries);                
            }  
            else {
                const newNotFormattedPriceEntries = Object.assign({}, notFormattedPriceEntries);
                newNotFormattedPriceEntries[entriesFormIdx] = null;
                setNotFormattedPriceEntries(newNotFormattedPriceEntries);
            } 

            if (! doNotAddPrice){
                const prices = text.split("\n\n");
                for (let i = 0; i < entriesFr.length; i++) {
                    if (i >= prices.length) continue;        
                    const pricesFromLine = prices[i].split("_");      
                    if (entriesFr[i].entryPrice.fr)  
                        entriesFr[i].entryPrice.en = pricesFromLine;
                }
            }

            return entriesFr;
        }

        function tableToTextImgUrls(entries){
            let urls = "";
            for (let i = 0; i < entries.length; i++) {
                if (entries[i].entryImage){
                    urls += entries[i].entryImage;   
                    if (i < entries.length - 1)             
                        urls += "\n\n";   
                }   
                else {
                    if (i < entries.length - 1)             
                        urls += "\n\n";
                }          
            }
            return urls;
        }

        function textToTableImgUrls(text, entriesFr){
            const imgUrls = text.split("\n\n");
            for (let i = 0; i < entriesFr.length; i++) {
                if (i >= imgUrls.length) continue;        
                
                if (imgUrls[i] !== "")
                    entriesFr[i].entryImage = [imgUrls[i]];
            }

            return entriesFr;
        }


        let entries = [];

        for (let i = 0; i < pageEntries.length; i++) {            
            if (pageEntries[i].entryType === "startingNote" || pageEntries[i].entryType === "endingNote"){
                continue;
            }            
                
            entries.push(<>{i > 0 && <><br/>---<br/></>}<div id={"entry-" + i} style={{marginTop: 20, width: '100%', display: "flex", flexDirection: "column", justifyContent: 'flex-start', alignItems: "center", alignContent: "center", justifyItems: "center"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}> 
                <TextField id="entry-type" type='text' style={{width: 240}} placeholder={"entry type"} inputProps={pageEntries[i].entryType ? {value: pageEntries[i].entryType} : {}} onChange={(e)=>{const newPage = Object.assign({}, page);newPage.pageEntries[i].entryType = e.target.value;setPage(newPage);}} />
                </div>
                <br/>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}> 
                <TextField id="entry-title" type='text' style={{marginTop: 20, width: 240}} placeholder={"entry title"} inputProps={pageEntries[i].entriesTitle && pageEntries[i].entriesTitle.fr ? {value: pageEntries[i].entriesTitle.fr} : {}} onChange={(e)=>{const newPage = Object.assign({}, page);if (newPage.pageEntries[i].entriesTitle) newPage.pageEntries[i].entriesTitle.fr = e.target.value; else newPage.pageEntries[i].entriesTitle = {"fr": e.target.value};setPage(newPage);}}/><TextField id="entry-title-en" type='text' style={{marginTop: 20, width: 240}} placeholder={"entry title"} inputProps={pageEntries[i].entriesTitle && pageEntries[i].entriesTitle.fr ? {value: pageEntries[i].entriesTitle.en} : {}} onChange={(e)=>{const newPage = Object.assign({}, page);if (newPage.pageEntries[i].entriesTitle) newPage.pageEntries[i].entriesTitle.en = e.target.value; else newPage.pageEntries[i].entriesTitle = {"en": e.target.value};setPage(newPage);}}/>
                </div>
                {
                pageEntries[i].entryType === "formula" &&
                    <TextField id="formulaPrice" type='text' style={{marginTop: 20, width: 240}} placeholder={"formula price"} inputProps={pageEntries[i].formulaPrice ? {value: pageEntries[i].formulaPrice} : {}} onChange={(e)=>{const newPage = Object.assign({}, page);if (e.target.value.trim() !== "") newPage.pageEntries[i].formulaPrice = e.target.value;setPage(newPage);}}/>
                }
                <br/>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}> 
                <TextField id="starting-note" type='text' style={{marginTop: 20, width: 240}} placeholder={"starting note"} inputProps={pageEntries[i].startingNote && pageEntries[i].startingNote.fr ? {value: pageEntries[i].startingNote.fr} : {}} onChange={(e)=>{const newPage = Object.assign({}, page);if (newPage.pageEntries[i].startingNote) newPage.pageEntries[i].startingNote.fr = e.target.value; else newPage.pageEntries[i].startingNote = {"fr": e.target.value};if (e.target.value === "") delete newPage.pageEntries[i].startingNote;setPage(newPage);}}/><TextField id="starting-note-en" type='text' style={{marginTop: 20, width: 240}} placeholder={"starting note"} inputProps={pageEntries[i].startingNote && pageEntries[i].startingNote.en ? {value: pageEntries[i].startingNote.en} : {}} onChange={(e)=>{const newPage = Object.assign({}, page);if (newPage.pageEntries[i].startingNote) newPage.pageEntries[i].startingNote.en = e.target.value; else newPage.pageEntries[i].startingNote = {"en": e.target.value};setPage(newPage);}}/>
                </div>
                <br/>
                <div style={{marginTop: 20, display: "flex", flexDirection: "row", alignItems: "center"}}>                                                        
                    <text id="dishes" style={{width: 600}}>Plats</text><text id="dishes-en" style={{marginLeft: 20, width: 600}}>Plats en anglais</text><text id="prices" style={{marginLeft: 20, width: 72}}>Prix</text><text id="urls" style={{marginLeft: 20, width: 72,}}>Url img</text>
                </div>
                <div style={{marginTop: 20, display: "flex", flexDirection: "row", alignItems: "center"}}>                                                        
                    <textarea id="dishes" style={{width: 600, height: 400}} value={pageEntries[i].entries ? tableToTextDishesFr(pageEntries[i].entries, i) : ""} onChange={(e)=>{const newPage = Object.assign({}, page);newPage.pageEntries[i].entries = textToTableDishesFr(e.target.value, pageEntries[i].entries, i);setPage(newPage);}}/><textarea id="dishes-en" style={{marginLeft: 20, width: 600, height: 400}} value={pageEntries[i].entries ? tableToTextDishesEn(pageEntries[i].entries) : ""} onChange={(e)=>{const newPage = Object.assign({}, page);newPage.pageEntries[i].entries = textToTableDishesEn(e.target.value, pageEntries[i].entries);setPage(newPage);}}/><textarea id="prices" style={{marginLeft: 20, width: 72, height: 400}} value={pageEntries[i].entries ? tableToTextPrices(pageEntries[i].entries, i) : ""} onChange={(e)=>{const newPage = Object.assign({}, page);newPage.pageEntries[i].entries = textToTablePrices(e.target.value, pageEntries[i].entries, i);setPage(newPage);}}/><textarea id="prices-en" style={{marginLeft: 20, width: 72, height: 400}} value={pageEntries[i].entries ? tableToTextPricesEn(pageEntries[i].entries, i) : ""} onChange={(e)=>{const newPage = Object.assign({}, page);newPage.pageEntries[i].entries = textToTablePricesEn(e.target.value, pageEntries[i].entries, i);setPage(newPage);}}/><textarea id="urls" style={{marginLeft: 20, width: 72, height: 400}} value={pageEntries[i].entries ? tableToTextImgUrls(pageEntries[i].entries) : ""} onChange={(e)=>{const newPage = Object.assign({}, page);newPage.pageEntries[i].entries = textToTableImgUrls(e.target.value, pageEntries[i].entries);setPage(newPage);}}/>
                </div>
                <br/>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}> 
                <TextField id="ending-note" type='text' style={{marginTop: 20, width: 240}} placeholder={"ending note"} inputProps={pageEntries[i].endingNote && pageEntries[i].endingNote.fr ? {value: pageEntries[i].endingNote.fr} : {}} onChange={(e)=>{const newPage = Object.assign({}, page);if (newPage.pageEntries[i].endingNote) newPage.pageEntries[i].endingNote.fr = e.target.value; else newPage.pageEntries[i].endingNote = {"fr": e.target.value};if (e.target.value === "") delete newPage.pageEntries[i].endingNote;setPage(newPage);}}/><TextField id="ending-note-en" type='text' style={{marginTop: 20, width: 240}} placeholder={"ending note"}  inputProps={pageEntries[i].endingNote && pageEntries[i].endingNote.en ? {value: pageEntries[i].endingNote.en} : {}} onChange={(e)=>{const newPage = Object.assign({}, page);if (newPage.pageEntries[i].endingNote) newPage.pageEntries[i].endingNote.en = e.target.value; else newPage.pageEntries[i].endingNote = {"en": e.target.value};setPage(newPage);}}/>
                </div>
                <br/>                
            </div></>);
        }       
        
        return <>{entries.map((entry) => entry)}</>;
    }

    const entries = returnEntries(page.pageEntries);
    console.log(JSON.stringify(page));

    const menuIdConf = {};
    if (menu != null){
        menuIdConf.value = menu.id;
    }
    const menuPagesConf = {};
    if (menu != null){
        menuPagesConf.value = menu.menuPages;
    }
    const pageIdConf = {};
    if (page.id)
        pageIdConf.value = page.id;

    const startingNoteIdx = page.pageEntries && page.pageEntries.length > 0 && page.pageEntries.findIndex((pageEntry)=>pageEntry.entryType === "startingNote");
    const endingNoteIdx = page.pageEntries && page.pageEntries.length > 0 && page.pageEntries.findIndex((pageEntry)=>pageEntry.entryType === "endingNote");
        
    const endingNoteInputProps = endingNoteIdx && endingNoteIdx != -1 ? {value: page.pageEntries[endingNoteIdx].text.fr} : {value: ""};
    const endingNoteEnInputProps = endingNoteIdx && endingNoteIdx != -1 ? {value: page.pageEntries[endingNoteIdx].text.en} : {value: ""};

    return (
        <div style={{width: '100%', backgroundColor: 'white', borderRadius: isTabletOrMobile ? 0 : 10,}}>
            <div style={{padding: 16,}}>
                <div style={{width: '100%', display: "flex", flexDirection: "column", justifyContent: 'flex-start', alignItems: "center", alignContent: "center", justifyItems: "center"}}>                    
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>                        
                        <div style={{fontSize: 28}}>{t('MenuEditor')}</div>
                    </div>    
                    <TextField id="menu-id" type='text' style={{marginTop: 20, width: 240}} placeholder={"menu id"} inputProps={menuIdConf} onChange={(e)=>{                        
                        setMenu({id: e.target.value, menuPages: menu != null ? menu.menuPages : []});
                    }}/>
                    <MaterialButton style="text" onClick={()=> {loadMenu()}} value={"load"}/>
                    <div>
                        Permettre allergènes et nutriments <Checkbox checked={permitAllergensAndNutrients} onChange={()=> {setPermitAllergensAndNutrients(!permitAllergensAndNutrients)}}  inputProps={{ 'aria-label': 'controlled' }}/>
                    </div>
                    <MaterialButton style="text" onClick={()=> {createMenuAndEntry()}} value={"create"}/>
                    <br/>
                    <TextField id="page-ids" name="page-ids" type='text' style={{marginTop: 20, width: "100%"}} placeholder={"pagesIds"} inputProps={menuPagesConf} onChange={(e) => {
                        setMenu({id: menu.id, menuPages: e.target.value.split(",")});
                    }}/><MaterialButton style="text" onClick={()=> {updateOrder()}} value={"update order"}/>
                    <br/>
                    <TextField id="page-id" type='text' style={{marginTop: 20, width: 240}} placeholder={"page id"} inputProps={pageIdConf} onChange={(e)=>{
                        setPage({id: e.target.value, pageEntries: []});
                    }} /><MaterialButton style="text" onClick={()=> {loadPage()}} value={"load"}/>                    
                    <br/>
                    pageType à remplir en premier, peut être vide
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>                     
                    <TextField id="page-type" type='text' style={{marginTop: 20, width: 240}} placeholder={"page type"} inputProps={page.pageType && page.pageType.fr ? {value: page.pageType.fr} : {value: ""}} onChange={(e)=>{const newPage = Object.assign({}, page);if (newPage.pageType == null) newPage.pageType = {};newPage.pageType.fr = e.target.value;setPage(newPage);}}/><TextField id="page-type-en" type='text' style={{marginTop: 20, width: 240}} placeholder={"page type"} inputProps={page.pageType ? {value: page.pageType.en} : {value: ""}} onChange={(e)=>{const newPage = Object.assign({}, page);newPage.pageType.en = e.target.value;setPage(newPage);}}/>
                    </div>
                    <br/>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}> 
                    <TextField id="page-starting-note" type='text' style={{marginTop: 20, width: 240}} placeholder={"starting note"} inputProps={page.pageEntries && page.pageEntries.length > 0 && page.pageEntries[0].entryType === "startingNote" ? {value: page.pageEntries[0].text.fr} : {value: ""}} onChange={(e)=>{const newPage = Object.assign({}, page);if (! newPage.pageEntries) newPage.pageEntries = [];if (startingNoteIdx == null || startingNoteIdx === -1) newPage.pageEntries.splice(0, 0, {centering: "center", entryType: "startingNote", text: {}});if (e.target.value.trim() === "") newPage.pageEntries.shift(); else newPage.pageEntries[0].text.fr = e.target.value;setPage(newPage);}}/><TextField id="page-starting-note-en" type='text' style={{marginTop: 20, width: 240}} placeholder={"starting note"}  inputProps={page.pageEntries && page.pageEntries.length > 0 && page.pageEntries[0].entryType === "startingNote" ? {value: page.pageEntries[0].text.en} : {value: ""}} onChange={(e)=>{const newPage = Object.assign({}, page);if (page.pageEntries[0].entryType === "startingNote") newPage.pageEntries[0].text.en= e.target.value;setPage(newPage);}}/>
                    </div>
                    <br/>
                    ---
                    {entries}  
                    {page.pageType && page.pageType.fr === "image" && 
                        <>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>                     
                                <TextField id="page-img-url" type='text' style={{marginTop: 20, width: 240}} placeholder={"page img url"} inputProps={page.pageImgUrl ? {value: page.pageImgUrl} : {value: ""}} onChange={(e)=>{const newPage = Object.assign({}, page);if (newPage.pageImgUrl == null) newPage.pageImgUrl = "";newPage.pageImgUrl = e.target.value;setPage(newPage);}}/>
                            </div>
                            <br/>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}> 
                            <TextField id="page-bg" type='text' style={{marginTop: 20, width: 240}} placeholder={"img bg #color"} inputProps={page.pageBackground ? {value: page.pageBackground} : {value: ""}} onChange={(e)=>{const newPage = Object.assign({}, page);if (newPage.pageBackground == null) newPage.pageBackground = "";newPage.pageBackground = e.target.value;setPage(newPage);}}/>
                            </div>
                        </>
                    }
                    <br/>
                    <MaterialButton style="text" onClick={()=> {const newPage = Object.assign({}, page);if (! newPage.pageEntries) newPage.pageEntries = [];if (endingNoteIdx == null || endingNoteIdx === -1) newPage.pageEntries.push({}); else {newPage.pageEntries.splice(newPage.pageEntries.length-1,0,{});}setPage(newPage);}} value={"add another"}/>
                    ---
                    <br/>  
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}> 
                    <TextField id="page-ending-note" type='text' style={{marginTop: 20, width: 240}} placeholder={"ending note"} inputProps={endingNoteInputProps} onChange={(e)=>{const newPage = Object.assign({}, page);if (! newPage.pageEntries) newPage.pageEntries = [];if (endingNoteIdx == null || endingNoteIdx === -1) newPage.pageEntries.push({centering: "center", entryType: "endingNote", text: {}});if (e.target.value.trim() === "") newPage.pageEntries.pop(); else newPage.pageEntries[newPage.pageEntries.length - 1].text.fr = e.target.value;setPage(newPage);}}/><TextField id="page-ending-note-en" type='text' style={{marginTop: 20, width: 240}} placeholder={"ending note"} inputProps={endingNoteEnInputProps} onChange={(e)=>{const newPage = Object.assign({}, page);if (endingNoteIdx != null && endingNoteIdx !== -1) newPage.pageEntries[endingNoteIdx].text.en = e.target.value;setPage(newPage);}}/>
                    </div>
                    <br/>
                    <TextField id="auth-token" type="text" placeholder={"auth token"} />
                    <MaterialButton style="text" onClick={()=> {
                        translatePage()
                    }} value={"translate"}/>
                    <br/>
                    <MaterialButton style="text" onClick={()=> {savePage()}} value={"save"}/>
                </div>                
                                             
            </div>
        </div>
    );
}

export default MenuEditor;