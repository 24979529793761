import {app, analyticsManager} from '../index';
import { getAuth, signInWithPopup, FacebookAuthProvider, linkWithPopup, signInWithRedirect } from "firebase/auth";
import * as Sentry from "@sentry/react";
import { getFirestore, doc, updateDoc, deleteField, collection, query, where, getDocs, arrayRemove, getDoc, setDoc, or, addDoc } from "firebase/firestore";
import { getStorage, ref, listAll, deleteObject, updateMetadata, uploadBytes } from "firebase/storage";
import { getFileNameWithoutFormat, isAudio, log, printObj, removeDiacritics } from '../player/Utils';
import { getFileFormat, getFileNameFromUrl, isImg, isVideo, getFileExtensionFromMimeType } from '../player/Utils';
import { getId, getInstallations } from '@firebase/installations';

export const MEDIA_TYPE_PROFILE = "profile";
export const MEDIA_TYPE_STORE_FRONT = "storefront";
export const MEDIA_TYPE_STORE_FRONT_TN = "storefronttn";
export const MEDIA_TYPE_STORE_FRONT_FS_TN = "storefrontfstn";
export const MEDIA_TYPE_AVATAR = "avatar";
export const MEDIA_TYPE_MUSIC = "music";
export const MEDIA_TYPE_INSIDE_PREVIEW = "insidepreview";
export const MEDIA_TYPE_MENU_PREVIEW = "menupreview";
export const MEDIA_TYPE_MENU_FR = "menu";
export const MEDIA_TYPE_MENU_EN = "menuen";
export const MEDIA_TYPE_DAY_MENU = "daymenu";
export const CHECK_MEDIA_CONTENT_REPLACE = "replace";
export const CHECK_MEDIA_CONTENT_ADD = "add";

export function loginToAccessInstagramContent(setAccessToken, storyId, appUid, alreadyLogged) {  
    const auth = getAuth(app);
    const provider = new FacebookAuthProvider();                
    provider.addScope('email');
    provider.addScope('instagram_basic');
    provider.addScope('pages_read_engagement');
    provider.addScope('pages_show_list');     
    provider.addScope('public_profile');
    provider.addScope('business_management');    
    provider.setCustomParameters({
        'display': 'popup'
      });                                       
    
    return signInWithPopup(auth, provider)
                .then((result) => createUserIfFirstLogin(result.user.uid, result.user.email, result.user.providerData[0].uid, result))
                .then((result) => {                                           
                    const credential = FacebookAuthProvider.credentialFromResult(result.getBack);                    
                    const accessToken = credential.accessToken;                     
                    setAccessToken(accessToken);                     
                    
                    if (! alreadyLogged){
                        saveAsPermStory(result.getBack.user, storyId, appUid);
                    } 

                    if (result.getBack.user.uid != appUid){
                        analyticsManager.logLoginFromAnonymousTag(result.getBack.user.uid, appUid);
                    }

                    return accessToken;
                }) 
                .then((accessToken) => getFBBusinessPages(accessToken));           
}

export function loginToAccessInstagramContentWithRedirect() {  
    const auth = getAuth(app);
    const provider = new FacebookAuthProvider();                
    provider.addScope('email');
    provider.addScope('instagram_basic');
    provider.addScope('pages_read_engagement');
    provider.addScope('pages_show_list');     
    provider.addScope('public_profile');
    provider.addScope('business_management');                                           
    
    return signInWithRedirect(auth, provider);           
}

export function associateEmailToFacebook(){
    const provider = new FacebookAuthProvider();

    const auth = getAuth();
    return linkWithPopup(auth.currentUser, provider);
}

export async function createUserIfFirstLogin(uid, email, fbid = null, getBack = null, recreateOnDeleteError = false){     
    let termsAccepted = false;

    const db = getFirestore();
    
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);

    if (! userSnap.exists()) {        
        await setDoc(doc(db, "users", uid), {
            email: email,
            fbid: fbid,
            termsAccepted: recreateOnDeleteError,
            createdTime: new Date().getTime(),
        });
    }
    else {        
        termsAccepted = userSnap.data().termsAccepted;
    }    

    return {termsAccepted: termsAccepted, getBack: getBack};
}

export async function isTermsAccepted(storyId, getBack = null){
    const userFromStory = await getUserFromStory(storyId);
    const uid = userFromStory.uid;
    const db = getFirestore();
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    return {termsAccepted: userSnap.data().termsAccepted, getBack: getBack};    
}

export async function getUserFromStory(storyId, getBack = null){
    const db = getFirestore();
    const docRef = doc(db, "stories", storyId);
    const docSnap = await getDoc(docRef);

    return {uid: docSnap.data().uid, getBack: getBack};
}

export async function getUser(uid){
    const db = getFirestore();
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);

    return userSnap.data();
}

export async function acceptTermsForUser(uid){
    const db = getFirestore();
         
    const docRef = doc(db, "users", uid);
    await updateDoc(docRef, {
        termsAccepted: true,
    });   
}

export async function updateStoryOrGoToStories(user, storyId, story){
    let navigateToLogged = false;
    const db = getFirestore();
    const storiesRef = collection(db, "stories");
    const q = query(storiesRef, where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);
            
    let existingStoryDoc = null;
    querySnapshot.forEach((doc) => {
        existingStoryDoc = doc;
        });
       
    // TODO remove code below because login from tab 0 is useless ? is it login from tab 0 ?
    if (existingStoryDoc != null && story.posId == null){       
        // TODO cant delete the story with the anonymous uid, will be deleted by batch            
        // await deleteDoc(doc(db, "stories", storyId));                        
        navigateToLogged = true;
    }   
    // first login, the preview story is upgraded to a standard story
    else {          
        saveAsPermStory(user, storyId, story.uid);        
    }   
    
    return navigateToLogged;
}

export function clearQueryString(){
    if (window.history.pushState) {   
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        window.history.pushState({path:newurl},'',newurl);
    }  
}

/**
 * 
 * @param {*} storyId be careful, the storyId should be the baseStoryId if the user is logged
 */
export function cleanMakerQueryString(storyId){
    if (window.history.pushState) {   
        var newurl = window.location.protocol + "//" + window.location.host + "/maker" + '/' + storyId;
        window.history.pushState({path:newurl},'',newurl);
    }  
}

export async function getFBBusinessPages(accessToken){
    return new Promise((resolve, reject) => {             
        if (window.FB == null){            
            return reject(new Error(JSON.stringify({error:"ErrorFBNotInitialized"})));
        }
        window.FB.api('/me',  {access_token: accessToken, fields: 'id'}, function(response) {       
            if (!response || response.error) {                  
                Sentry.captureMessage("Facebook login error: " + printObj(response));    
                return reject(new Error(JSON.stringify({error:"ErrorDefault"})));
            }
            else {                
                window.FB.api('/' + response.id + "/accounts",  {access_token: accessToken, fields: 'id,name,connected_instagram_account'}, function(response) {                                              
                    if (!response || response.error){                        
                        Sentry.captureMessage("Facebook get accounts error: " + printObj(response));
                        return reject(new Error(JSON.stringify({error:"ErrorDefault"})));
                    }     
                    else if(response.data == null || response.data.length === 0){
                        return reject(new Error(JSON.stringify({error:"ErrorNoFacebookPage"})));
                    }               
                    else {                           
                        const noInstagramAccount = response.data.find((elt)=>elt.connected_instagram_account != null) == null;
                        if (noInstagramAccount){
                            return reject(new Error(JSON.stringify({error:"ErrorNoInstagramAccount"})));
                        }   
                        resolve(response.data.filter((elt)=>elt.connected_instagram_account != null));                 
                    }                                
                });
            }
            
        });
    });
}

export async function saveAsPermStory(user, storyId, appUid){    
    const db = getFirestore();

    // first login, the preview story is upgraded to a standard story
    const docRef = doc(db, "stories", storyId);
    await updateDoc(docRef, {
        uid: user.uid,
        anonymousUid: appUid,
        baseStoryId: storyId,
        baseStory: true, // no edition copy exists at this time
        preview: deleteField(),   
        neverPublishedAsLogged: true,     
        savedTime: new Date().getTime(),
    });
    
    const storage = getStorage();   
    const storyStorageRef = ref(storage, 'restaurants/' + appUid + "/" + storyId);   
    
    // Find all the prefixes and items.
    await listAll(storyStorageRef)
        .then(async (res) => {              
            for (const itemRef of res.items){
                const newOwnerMetadata = {
                    customMetadata: {
                      'uid': user.uid,                          
                    }
                  };  
                await updateMetadata(itemRef, newOwnerMetadata);
            }                      
        });  

    const storyForTN = await getDoc(docRef);    
    await updateMediasForTransformation(storyId, storyForTN.data(), null, user.uid);
    
}

export async function updateMediasForTransformation(storyId, story, baseStory, uid){
    if (story.imageRef != null && (baseStory == null || baseStory.imageRef !== story.imageRef)){
        await updateMediaForTransformation(story.imageRef, story.width, story.height, storyId, uid);
    }
    else if (story.videoRef != null && (baseStory == null || baseStory.videoRef !== story.videoRef)){
        await updateMediaForTransformation(story.videoRef, story.width, story.height, storyId, uid);        
    }

    if (story.avatarUrl != null && (baseStory == null || baseStory.avatarUrl !== story.avatarUrl) && story.avatarUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){
        await updateMediaForTransformation(story.avatarUrl, null, null, storyId, uid);
    }

    if (story.musicUrl != null && (baseStory == null || baseStory.musicUrl !== story.musicUrl) && story.musicUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){
        await updateMediaForTransformation(story.musicUrl, null, null, storyId, uid);
    }

    if (story.mediasRef != null){
        for (const media of story.mediasRef){
            if (! (baseStory != null && baseStory.mediasRef != null && baseStory.mediasRef.includes(media)))
                await updateMediaForTransformation(media, null, null, storyId, uid);
        }
    }

    if (story.tourMediasRef != null){
        for (const media of story.tourMediasRef){            
            if (! (baseStory != null && baseStory.tourMediasRef != null && baseStory.tourMediasRef.includes(media)))
                await updateMediaForTransformation(media, null, null, storyId, uid);
        }
    }

    if (story.dayMenuFile != null && (baseStory == null || baseStory.dayMenuFile !== story.dayMenuFile)){
        await updateMediaForTransformation(story.dayMenuFile, null, null, storyId, uid);
    }
}

export async function updateMediaForTransformation(mediaUrl, width, height, storyId, uid){
    const storage = getStorage();   
    const storyStorageRef = ref(storage, mediaUrl.substring(mediaUrl.indexOf("restaurants/"))); 

    const newOwnerMetadata = {
        customMetadata: {
          'published_for': storyId,     
          // keep uid for fb rules   
          'uid': uid,           
        }        
      }; 

    if (width != null && height != null){
        newOwnerMetadata.customMetadata.width = width;
        newOwnerMetadata.customMetadata.height = height;
    } 
    await updateMetadata(storyStorageRef, newOwnerMetadata);
}

export async function onPageSelectedForImages(storyId, accessToken, mediaContentType){
    const instagramBusinessAccountId = document.querySelector('input[name="facebook-page"]:checked').value;  

    const getIGInfos = () => {return new Promise((resolve, reject) => {
        window.FB.api('/' + instagramBusinessAccountId,  {access_token: accessToken, fields: 'name,username'}, async function(response) {
            if (!response || response.error){
                if (response.error)                    
                    Sentry.captureMessage(printObj(response.error));            
                return reject(new Error(JSON.stringify({error:"ErrorDefault"}))); 
            }     
            if (mediaContentType === MEDIA_TYPE_PROFILE)         
                await saveInstagramUserName(storyId, response.name, response.username);            
            resolve(instagramBusinessAccountId);
        });
    })};
    return getIGInfos();    
}

export async function saveInstagramProfile(storyId, instagramBusinessAccountId, accessToken, fromFBOverIG = true){

    if (fromFBOverIG){                
        const getIGInfos = () => {return new Promise((resolve, reject) => {
            window.FB.api('/' + instagramBusinessAccountId,  {access_token: accessToken, fields: 'name,username'}, async function(response) {
                if (!response || response.error){
                    if (response.error)                    
                        Sentry.captureMessage(printObj(response.error));            
                    return reject(new Error(JSON.stringify({error:"ErrorDefault"}))); 
                }          
                await saveInstagramUserName(storyId, response.name, response.username);            
                resolve();
            });
        })};
        return getIGInfos();   
    }
   else{  
        return fetch("https://graph.instagram.com/me?fields=id,username,profile_picture_url&access_token=" + accessToken)
            .then((response) => response.json())                    
            .then((response) => saveInstagramUserName(storyId, response.username, response.username));                 
    }        
}

export async function saveInstagramUserName(storyId, name, userName){
    const db = getFirestore();
    const docRef = doc(db, "stories", storyId);    
    await updateDoc(docRef, {
        instagramProfileName: name,
        instagramProfileUserName: userName,
        posChatLink: "https://ig.me/m/" + userName,
        savedTime: new Date().getTime(),
    });
}

export async function saveAvatarUrl(storyId, url){
    const db = getFirestore();        
    const docRef = doc(db, "stories", storyId);
    if (url === "")
        url = deleteField();
    const update = {
        avatarUrl: url,
        savedTime: new Date().getTime(),
    };

    await updateDoc(docRef, update);
}

export async function saveMusicUrl(storyId, url){
    const db = getFirestore();        
    const docRef = doc(db, "stories", storyId);
    if (url === "")
        url = deleteField();
    const update = {
        musicUrl: url,
        savedTime: new Date().getTime(),
    };

    await updateDoc(docRef, update);
}

export async function getAvatarFromInstagram(accessToken, instagramBusinessAccountId){
    let result = null;    
    const getAvatar = () => {return new Promise((resolve, reject) => {
        window.FB.api('/' + instagramBusinessAccountId,  {access_token: accessToken, fields: 'profile_picture_url'}, function(response) {
            if (!response || response.error || response.profile_picture_url == null || response.profile_picture_url === "") {
                if (!response || response.error){
                    Sentry.captureMessage("Facebook get ig profile picture for avatar error: " + printObj(response));
                    return reject(new Error(JSON.stringify({error:"ErrorDefault"})));
                }
                else {
                    return reject(new Error(JSON.stringify({error:"ErrorNoIGProfilePicture"})));
                }
            }  
            
            resolve(response.profile_picture_url);                              
        });
    })};
    result = await getAvatar();

    return result;
}

export async function getMediaContentsFromInstagram(accessToken, instagramBusinessAccountId/*, onIGGetImagesError, onSuccess*/){
    let result = null;

    const getMedias = () => {return new Promise((resolve, reject) => {
        window.FB.api('/' + instagramBusinessAccountId + '/media',  {access_token: accessToken, fields: 'media_url,thumbnail_url,media_type'}, function(response) {
        if (!response || response.error) {  
            if (response.error)
                Sentry.captureMessage(printObj(response.error));
            return  reject(new Error(JSON.stringify({error:"onIGGetImagesError"})));
        }  
        resolve(response);                          
        });
    })};
    result = await getMedias();

    return result;
}

export async function checkMediaContent(file, story, storyId, baseStory, mediaContentType, showProgress, checkMediaContentOption, mediaUrl = null) {       
    if (file == null) return;       
    
    let fileFormat = getFileFormat(getFileNameFromUrl(file.name));
    if (fileFormat == null && file.type != null)
        fileFormat = getFileExtensionFromMimeType(file.type);
    
    if (mediaContentType === MEDIA_TYPE_STORE_FRONT || mediaContentType === MEDIA_TYPE_INSIDE_PREVIEW || mediaContentType === MEDIA_TYPE_MENU_PREVIEW){
        if (! isImg(fileFormat) && ! isVideo(fileFormat)){
            throw new Error(JSON.stringify({error:"FileFormatError"}));        
        }            
    }
    else if (mediaContentType === MEDIA_TYPE_AVATAR || mediaContentType === MEDIA_TYPE_DAY_MENU){
        if (! isImg(fileFormat)){
            throw new Error(JSON.stringify({error:"FileFormatError"}));        
        }
    }
    else if (mediaContentType === MEDIA_TYPE_MENU_FR){
        if (! isImg(fileFormat) && fileFormat.toLowerCase() !== "pdf"){
            throw new Error(JSON.stringify({error:"FileFormatError"}));        
        }
    }
    else if (mediaContentType === MEDIA_TYPE_MENU_EN){
        if (! isImg(fileFormat) && fileFormat.toLowerCase() !== "pdf"){
            throw new Error(JSON.stringify({error:"FileFormatError"}));        
        }
    }
    else if (mediaContentType === MEDIA_TYPE_MUSIC){
        if (! isAudio(fileFormat)){
            throw new Error(JSON.stringify({error:"FileFormatError"}));        
        }
    }

    if (isVideo(fileFormat) && file.size > 50 * 1024 * 1024){  
        throw new Error(JSON.stringify({error:"FileSizeError", option: {size: 50}}));
    }
    if (isImg(fileFormat) && file.size > 10 * 1024 * 1024){   
        throw new Error(JSON.stringify({error:"FileSizeError", option: {size: 10}}));     
    }
    if (isImg(fileFormat) && mediaContentType === MEDIA_TYPE_AVATAR && file.size > 512 * 1024){   
        throw new Error(JSON.stringify({error:"FileSizeError", option: {size: 0.5}}));     
    }
    if (isAudio(fileFormat) && mediaContentType === MEDIA_TYPE_MUSIC && file.size > 10 * 1024 * 1024){   
        throw new Error(JSON.stringify({error:"FileSizeError", option: {size: 10}}));     
    }
                
    showProgress(true);    

    if (checkMediaContentOption === CHECK_MEDIA_CONTENT_REPLACE){
        await deleteExistingMediaContent(story, storyId, baseStory, mediaContentType, mediaUrl); 
        if (mediaContentType === MEDIA_TYPE_STORE_FRONT){
            await deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_STORE_FRONT_TN, story.shareTnRef);
            await deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_STORE_FRONT_FS_TN, story.videoFSTnRef);
        }
    }
}

export async function uploadMediaContent(story, storyId, file, mediaContentType, source){   
    const storage = getStorage();
    const editionVersion = story.baseStoryId != null;
    const storyIdForUpload = editionVersion ? story.baseStoryId : storyId;
    let storageFileName =  mediaContentType + "-";

    const fileName = file.name != null ? getFileNameWithoutFormat(file.name) + new Date().getTime() : new Date().getTime(); 
    const fileExtension = getFileFormat(file.name);
    storageFileName += fileName + (fileExtension != null ? "." + fileExtension : "");

    const mediaContentRef = ref(storage, 'restaurants/' + story.uid + "/" + storyIdForUpload + '/' + storageFileName);
    
    let url = null;
    

    let mediaUrl = "https://storage.googleapis.com/" + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;

    await uploadBytes(mediaContentRef, file).then(() => {
        url = mediaUrl + "/restaurants/" + story.uid + "/" + storyIdForUpload + "/" + storageFileName;                                       
        });       

    return url;
}  

export async function deleteThumbnailImg(storyId){
    const db = getFirestore();
    const docRef = doc(db, "stories", storyId);    
    await updateDoc(docRef, {        
        shareTnRef: deleteField(),
        savedTime: new Date().getTime(),
    });
}

export async function deleteFSThumbnailImg(storyId){
    const db = getFirestore();
    const docRef = doc(db, "stories", storyId);    
    await updateDoc(docRef, {        
        videoFSTnRef: deleteField(),
        savedTime: new Date().getTime(),
    });
}

export async function deleteExistingMediaContent(story, storyId, baseStory, mediaContentType, mediaUrl = null){       
    if (mediaContentType === MEDIA_TYPE_STORE_FRONT){
        if (story.imageRef != null){                    
            await deleteStoryMediaContent(story, storyId, story.imageRef, baseStory != null ? baseStory.imageRef : null);
        }
        else if (story.videoRef != null){                    
            await deleteStoryMediaContent(story, storyId, story.videoRef, baseStory != null ? baseStory.videoRef : null);
        }    
    }
    else if (mediaContentType === MEDIA_TYPE_STORE_FRONT_TN){
        if (story.shareTnRef != null){                   
            await deleteStoryMediaContent(story, storyId, story.shareTnRef, baseStory != null ? baseStory.shareTnRef : null);
        }
    }
    else if (mediaContentType === MEDIA_TYPE_STORE_FRONT_FS_TN){
        if (story.videoFSTnRef != null){                   
            await deleteStoryMediaContent(story, storyId, story.videoFSTnRef, baseStory != null ? baseStory.videoFSTnRef : null);
        }
    }
    else if (mediaContentType === MEDIA_TYPE_AVATAR){
        if (story.avatarUrl != null){                   
            await deleteStoryMediaContent(story, storyId, story.avatarUrl, baseStory != null ? baseStory.avatarUrl : null);
        }
    }
    else if (mediaContentType === MEDIA_TYPE_MUSIC){
        if (story.musicUrl != null){                   
            await deleteStoryMediaContent(story, storyId, story.musicUrl, baseStory != null ? baseStory.musicUrl : null);
        }
    }
    else if (mediaContentType === MEDIA_TYPE_INSIDE_PREVIEW){
        if (story.tourMediasRef != null){                   
            await deleteStoryMediaContent(story, storyId, story.tourMediasRef, baseStory != null ? baseStory.tourMediasRef : null, mediaUrl);
        }
    }
    else if (mediaContentType === MEDIA_TYPE_MENU_PREVIEW){
        if (story.mediasRef != null){                  
            await deleteStoryMediaContent(story, storyId, story.mediasRef, baseStory != null ? baseStory.mediasRef : null, mediaUrl);
        }
    }
    else if (mediaContentType === MEDIA_TYPE_MENU_FR){
        if (story.menuFile != null){                   
            await deleteStoryMediaContent(story, storyId, story.menuFile, baseStory != null ? baseStory.menuFile : null);
        }
    }
    else if (mediaContentType === MEDIA_TYPE_MENU_EN){
        if (story.menuFileEn != null){                   
            await deleteStoryMediaContent(story, storyId, story.menuFileEn, baseStory != null ? baseStory.menuFileEn : null);
        }
    }
    else if (mediaContentType === MEDIA_TYPE_DAY_MENU){
        if (story.dayMenuFile != null){                   
            await deleteStoryMediaContent(story, storyId, story.dayMenuFile, baseStory != null ? baseStory.dayMenuFile : null);
        }
    }

}

export async function deleteStoryMediaContent(story, storyId, storyField, baseStoryField, mediaUrl = null){
    const storage = getStorage();
    const editionVersion = story.baseStoryId != null;
                           
    // media associated with a monovalue field
    if (mediaUrl == null){       
        if (storyField != null && storyField.startsWith(process.env.REACT_APP_STORAGE_LOCATION) && (!editionVersion || story.baseStoryId === storyId || baseStoryField !== storyField)){                            
            const oldStoreFrontRef = ref(storage, storyField.substring(storyField.indexOf("restaurants/")));                    
            await deleteObject(oldStoreFrontRef).catch((error) => {});
        }       
    }
    // media associated with a multivalue field
    else {
        //console.log("deleteStoryMediaContent mediaUrl " + mediaUrl + " baseStoryField " + baseStoryField);
        if (storyField != null && mediaUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION) && (!editionVersion || story.baseStoryId === storyId || baseStoryField === null || baseStoryField === undefined || !baseStoryField.includes(mediaUrl))){                
            const oldStoreFrontRef = ref(storage, mediaUrl.substring(mediaUrl.indexOf("restaurants/")));                    
            await deleteObject(oldStoreFrontRef).catch((error) => {});
        }
    }
}

export function deleteFromStorage(fileRef){
    const storage = getStorage();
    const storageRef = ref(storage, fileRef.substring(fileRef.indexOf("restaurants/")));       
    deleteObject(storageRef).catch((error) => {});
}

export async function deleteAllStorage(story, storyId, filesToKeep = []){
    const storage = getStorage();   
    const storyStorageRef = ref(storage, 'restaurants/' + story.uid + "/" + storyId);   
    // Find all the prefixes and items.
    await listAll(storyStorageRef)
        .then(async (res) => {                        
            for (const itemRef of res.items){
                if (! filesToKeep.includes(itemRef.name))
                    await deleteObject(itemRef).catch((error) => {});
            }
        });    

    // still stored in a previous owner storage
    if (story.previousUids != null){
        story.previousUids.forEach(async (uid) => {
            const previousStoryStorageRef = ref(storage, 'restaurants/' + uid + "/" + storyId);   
            // Find all the prefixes and items.
            await listAll(previousStoryStorageRef)
                .then(async (res) => {            
                    for (const itemRef of res.items){
                        if (! filesToKeep.includes(itemRef.name))
                            await deleteObject(itemRef).catch((error) => {});
                    };
                }); 
        });
    }

    // still stored in anonymous storage
    if (story.anonymousUid != null){
        const anonymousStorageRef = ref(storage, 'restaurants/' + story.anonymousUid + "/" + storyId);   
        // Find all the prefixes and items.
        await listAll(anonymousStorageRef)
            .then(async (res) => {            
                for (const itemRef of res.items){
                    if (! filesToKeep.includes(itemRef.name))
                        await deleteObject(itemRef).catch((error) => {});
                };
            }); 
    }
}

export async function deleteInsideMediaDB(storyId, media){
    const db = getFirestore();        
    const docRef = doc(db, "stories", storyId);
    const update = {
        tourMediasRef: arrayRemove(media),
        savedTime: new Date().getTime(),
    };
    await updateDoc(docRef, update).then(
        () => deleteInsideMediaField(docRef)
    ); 
}                            

async function deleteInsideMediaField(docRef){
    const story = await getDoc(docRef);    
    if (story.data().tourMediasRef != null && story.data().tourMediasRef.length === 0){
        const update2 = {
            tourMediasRef: deleteField(),
            savedTime: new Date().getTime(),
        };
        updateDoc(docRef, update2);            
    } 
}

export async function logloginByRedirect(analyticsManager, uid){
    const previous_user_id = window.localStorage.getItem('previous_user_id');
    const previous_user_id_type = window.localStorage.getItem('previous_user_id_type');
    if (previous_user_id != null && previous_user_id_type != null){
        if (previous_user_id_type === "anonymous")
            analyticsManager.logLoginFromAnonymousTag(uid, previous_user_id);
        else{
            const firebaseInstallationId = await getId(getInstallations(app));
            analyticsManager.logFBIdToLoggedTag(firebaseInstallationId, uid);
        }
        window.localStorage.removeItem('previous_user_id');
        window.localStorage.removeItem('previous_user_id_type');
    }
}

export async function updateMainColor(storyId, type, color){
    const db = getFirestore();        
    const docRef = doc(db, "stories", storyId);

    const update = {
        savedTime: new Date().getTime(),
    };

    switch(color){
        case "primaryColor":
            update.primaryColor = type;            
            break;
        case "secondaryColor":
            update.secondaryColor = type;            
            break;
    }

    await updateDoc(docRef, update);        
}


export async function updateAlias(storyId, currentAlias, previousAlias, aliasProposition){    

    let aliasAlreadyExistsForAnotherStory = false;
    if (aliasProposition.toLowerCase() !== currentAlias.toLowerCase() && aliasProposition.toLowerCase() !== previousAlias.toLowerCase())
        aliasAlreadyExistsForAnotherStory = await aliasExists(aliasProposition);

    if (! aliasAlreadyExistsForAnotherStory){
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const update = {    
            alias: aliasProposition,    
            aliasLowerCase: aliasProposition.toLowerCase(),
            previousAlias: currentAlias,
            previousAliasLowerCase: currentAlias.toLowerCase(),
            storyLink: process.env.REACT_APP_HOST + "/p/" + aliasProposition,     
            savedTime: new Date().getTime(),
        };              
    
        await updateDoc(docRef, update);

        return true;
    }
    else {
        return false;
    }
    
}

export async function getRestaurantAlias(restaurantName, city, story, storyId){
    
    let restaurantAlias = cleanAliasProposition(restaurantName, city);    

    // test existence
    const aliasAlreadyExists = await aliasExists(restaurantAlias);

    // add city if already exists
    if (aliasAlreadyExists){
        restaurantAlias += city.charAt(0).toUpperCase() + city.slice(1);

        const aliasWithCityExists = await aliasExists(restaurantAlias);

        // set to story id if +city already exists
        if (aliasWithCityExists){
            restaurantAlias = story.baseStoryId != null ? story.baseStoryId : storyId;
        }
    }

    return restaurantAlias;
}

export function cleanAliasProposition(aliasProposition, city){
    // https://firebase.google.com/docs/firestore/quotas?hl=fr#limits     
    
    // convert accents
    let restaurantNameEscaped = removeDiacritics(aliasProposition);
    // remove special characters
    restaurantNameEscaped = restaurantNameEscaped.replace(/[^a-zA-Z0-9- ]/g, '');
    if (city && restaurantNameEscaped === ""){
        restaurantNameEscaped = "restaurant " + city;
    }
    restaurantNameEscaped = restaurantNameEscaped.toLowerCase();
    // capitalize first letter of each word and remove spaces
    const restaurantWords = restaurantNameEscaped.split(" ");
    let restaurantAlias = "";
    restaurantWords.forEach(word => {
        restaurantAlias += word.charAt(0).toUpperCase() + word.slice(1);
    });

    return restaurantAlias;
}

export async function aliasExists(alias){
    const db = getFirestore();        
    const storiesRef = collection(db, "stories");
    const q = query(storiesRef, or(where("aliasLowerCase", "==", alias.toLowerCase()), where("previousAliasLowerCase", "==", alias.toLowerCase())));
    const querySnapshot = await getDocs(q);
    let existingStoryDoc = null;
    querySnapshot.forEach((doc) => {
        existingStoryDoc = doc;
    });

    return existingStoryDoc != null;
}

export function sendMenuOptimizedEmail(storyId, restaurantName){
    const db = getFirestore();        
    addDoc(collection(db, "mail"), {
        to: "jacques@upreality.store",
        from: "Jacques - UpReality <jacques@upreality.store>",
        message: {
            subject: "UpReality 🌊 - demande d'optimisation de menu",                    
            text: "Une demande d'optimisation du menu a été demandé pour la story " + storyId + " du restaurant " + restaurantName + ". Pensez à vérifier l'état final de demande de menu dans la story.",
        },
    });             
}