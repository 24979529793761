import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import './ProductPage.css';
import MaterialButton from "./maker/component/MaterialButton";
import Faq from "react-faq-component";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import LoginDialog from "./maker/LoginDialog";
import { Alert, Snackbar } from "@mui/material";
import { browserLocales } from "./player/Utils";
import { clearQueryString } from "./maker/MakerController";
import { analyticsManager, app } from ".";
import { getAuth } from "firebase/auth";
import { getId, getInstallations } from "@firebase/installations";
import Player from "./player/Player";
import LineBreak from "./maker/component/LineBreak";
import ImageModal from "./maker/component/ImageModal";
import Create1ClicDialog from "./maker/component/Create1ClicDialog";

function InstantAdHP(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [showLogin, setShowLogin] = useState(false);
    const [showAckDisconnected, setShowAckDisconnected] = useState(new URLSearchParams(window.location.search).get("ackDisconnected") === "true");
    const [showAckAccountDeleted, setShowAckAccountDeleted] = useState(new URLSearchParams(window.location.search).get("ackAccountDeleted") === "true");
    // Should not be used here (redirection to the main HP)
    const [showErrorEmailLinkExpired, setShowErrorEmailLinkExpired] = useState(new URLSearchParams(window.location.search).get("errLinkExpired") === "true");    
    const [showErrorRegistrationLimitReached, setShowErrorRegistrationLimitReached] = useState(new URLSearchParams(window.location.search).get("errRegistrationLimit") === "true");    
    const [showError, setShowError] = useState(new URLSearchParams(window.location.search).get("error") === "true");    
    const [loginTitle, setLoginTitle] = useState(new URLSearchParams(window.location.search).get("login_title"));
    const [showCreate1Clic, setShowCreate1Clic] = useState(false);
    const language = browserLocales(true)[0];

    document.title = t('ProductPageAdTitle');

    useEffect(() => {    
        getAuth().authStateReady()
            .then(() =>{  
                const currentUser = getAuth().currentUser;
                if (currentUser == null){
                    setUserIdFromFirebaseId();
                }        
            });

        async function setUserIdFromFirebaseId() {
            const firebaseInstallationId = await getId(getInstallations(app));            
            analyticsManager.setUserId(firebaseInstallationId);
            analyticsManager.logHomePageAdScreenViewTag();
        }
      }, []);

      const scriptList = document.querySelectorAll("script[type='text/javascript']");
      const convertedNodeList = Array.from(scriptList);
      const hubspotScript = convertedNodeList.find(script => script.id === "hs-script-loader");

      useEffect(() => {
        if (!isTabletOrMobile && hubspotScript == null){
            const script = document.createElement('script');
        
            script.id = "hs-script-loader";
            script.src = "//js-eu1.hs-scripts.com/143936779.js";
            script.async = true;
            script.defer = true;
            script.type = "text/javascript";
        
            document.body.appendChild(script);
        
            // do not remove script when unmounting
        }

        const faqTexts = document.getElementsByClassName("row-content-text");
        if (faqTexts != null){
            for (let i = 0; i < faqTexts.length; i++) {
                faqTexts[i].style.whiteSpace = "pre-line";
            }
        }
      }, []);

    useEffect(() => {
        function logFaq(position, faqTheme){
            return () => {
                if (document.getElementsByClassName("row-title")[position].className.includes("closed"))
                    analyticsManager.logReadFAQTag("hp_ad", faqTheme);
            };
        }        

        const handleClickListener1 = logFaq(0, "instantAd");
        const handleClickListener2 = logFaq(1, "instantAdDifference");
        const handleClickListener3 = logFaq(2, "socialAdDest");        
        const handleClickListener4 = logFaq(3, "free");

        document.getElementsByClassName("row-title")[0].addEventListener("click", handleClickListener1);
        document.getElementsByClassName("row-title")[1].addEventListener("click", handleClickListener2);
        document.getElementsByClassName("row-title")[2].addEventListener("click", handleClickListener3);
        document.getElementsByClassName("row-title")[3].addEventListener("click", handleClickListener4);

        return () => {
            window.removeEventListener('click', handleClickListener1);
            window.removeEventListener('click', handleClickListener2);
            window.removeEventListener('click', handleClickListener3);
            window.removeEventListener('click', handleClickListener4);
            };
    }, []);

    document.getElementsByTagName('body')[0].className = 'WhiteBackground';
    document.getElementById("theme-color").setAttribute("content", "#FFFFFF");

    const data = {
        title: t("FAQCatUprealityOffer"),
        rows: [            
            {
                title: t("FAQInstantAdQuestion"),
                content: t("FAQInstantAdAnswer"),
            },   
            {
                title: t("FAQInstantAdDifferenceQuestion"),
                content: t("FAQInstantAdDifferenceAnswer"),
            },  
            {
                title: t("FAQSocialAdDestQuestion"),
                content: t("FAQSocialAdDestAnswer"),
            },  
            {
                title: t("FAQPriceQuestion"),
                content: t("FAQPriceAnswer"),
            },
        ],
    };
    
    const styles = {
        titleTextColor: "#1D192B",
        rowTitleColor: "#1D192B",
        arrowColor: "#2FC4B5",
        rowContentPaddingTop: "10px",
        rowContentPaddingBottom: "10px", 
    };
    
    const config = {};

    function onLogin(user){
        navigate("/logged");
    }

    function showAckDisconnectedSnack(){
        if (showAckDisconnected){
            clearQueryString();
            return (
                <Snackbar open={showAckDisconnected} onClose={()=>setShowAckDisconnected(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowAckDisconnected(false)} severity="success" sx={{ width: '100%' }}>     
                    {t('AckDisconnected')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    function showAckAccountDeletedSnack(){
        if (showAckAccountDeleted){            
            return (
                <Snackbar open={showAckAccountDeleted} onClose={()=>setShowAckAccountDeleted(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowAckAccountDeleted(false)} severity="success" sx={{ width: '100%' }}>     
                    {t('AckAccountDeleted')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    function showErrorEmailLinkExpiredSnack(){
        if (showErrorEmailLinkExpired){
            clearQueryString();
            return (
                <Snackbar open={showErrorEmailLinkExpired} autoHideDuration={6000} onClose={()=>setShowErrorEmailLinkExpired(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowErrorEmailLinkExpired(false)} severity="error" sx={{ width: '100%' }}>     
                    {t('ErrorEmailLinkExpired')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    function showErrorRegistrationLimitReachedSnack(){
        if (showErrorRegistrationLimitReached){
            clearQueryString();
            return (
                <Snackbar open={showErrorRegistrationLimitReached} autoHideDuration={8000} onClose={()=>setShowErrorRegistrationLimitReached(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowErrorRegistrationLimitReached(false)} severity="error" sx={{ width: '100%' }}>     
                    {t('ErrorSubscriptionLimitReached')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    function showErrorSnack(){
        if (showError){
            clearQueryString();
            return (
                <Snackbar open={showError} autoHideDuration={8000} onClose={()=>setShowError(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowError(false)} severity="error" sx={{ width: '100%' }}>     
                    {t('ErrorDefault')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    if (! isTabletOrMobile){
        const deskTop =  window.innerHeight >= 80 + 16 + 576 + 16
        const appBarHeight = deskTop ? 80 : 60;
        const offsetToIndicateScroll = deskTop ? 100 : 20;
        const spaceBetweenFirstScreenAndSecond = deskTop ? 60 : 0;
        const logoSize = deskTop ? 42 : 26;
        const emojiSize = deskTop ? 26 : 16;
        const deviceBgHeight = deskTop ? 576 : 516;
        const storyTopMargin = deskTop ? 16 : 14;
        const storyHeight = deskTop ? 540 : 486;
        const logo_underline_margin_left = deskTop ? 26 : 20;
        const logo_underline_width = deskTop ? 180 : 100;
        const beta_underline_margin_left = deskTop ? 580 : 640;
        const beta_underline_width = deskTop ? 220 : 160;
        const specialMargin = deskTop ? 70 : 60;

        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>                
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", alignContent: "center", width: 1152}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><div style={{color: "#04d3bc", fontSize: logoSize, fontWeight: "bold"}}>UpReality </div> <div style={{fontSize: emojiSize,}}>&nbsp;🌊</div></div>
                    <div style={{display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                        <img src="/images/ribbon_beta_right.png" height={appBarHeight} alt="beta ribbon" style={{marginRight: 40}}/>                        
                        <MaterialButton style="text" onClick={()=>{analyticsManager.logOpenPopupTag(null, "home_ad_login", true);setShowLogin("login");setLoginTitle(t('Login'));}} value={t("Login")} />
                        <div style={{marginRight: 16}}/>
                        <MaterialButton style="outlined-tonal" onClick={()=>{analyticsManager.logOpenPopupTag(null, "home_ad_create_account", true);setShowLogin("account");setLoginTitle(t('CreateAccount'));}} value={t("CreateAccount")} />
                    </div>
                </div>
                {<div style={{width: 1152}}>
                    <div style={{marginLeft: logo_underline_margin_left, width: logo_underline_width, height: 1, background: "radial-gradient(#b2ede7, #ffffff)"}} />
                    <div style={{marginLeft: beta_underline_margin_left, marginTop: -1, width: beta_underline_width, height: 1, background: "radial-gradient(#b2ede7, #ffffff)"}} />
                </div>}
                <div className="FirstSectionContainer" style={{height: window.innerHeight - offsetToIndicateScroll - appBarHeight, width: '100%', display: "flex", flexDirection: "column", justifyContent: 'center'}}>             
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center',}}>
                        <div style={{width: 500, marginTop: 100}}>
                            <h1>{t("InstantAdHero1")}&nbsp;<div className="yellowParallelogram"><div className="textOverYellowParallelogram">{t("InstantAdHero2")}&nbsp;</div></div><br/>{t("InstantAdHero3")}</h1>
                            {t("InstantAdHero41")} {t("InstantAdHero42")}
                            <br/><br/><br/>      
                            <MaterialButton style="filled" onClick={()=>{setShowCreate1Clic("ad_create1Click");analyticsManager.logOpenPopupTag(null, "ad_create1Click", true);}}  value={t("CTA")} /> 
                            <div style={{marginTop: 20}}/> 
                            <MaterialButton style="outlined-primary" onClick={()=>{analyticsManager.logHPTryMakerTag("top_ad");navigate("/maker");}}  value={t("InstantAdCTA")} /> <br/> 
                            {<img src="/images/live-free.png" style={{marginTop: specialMargin, marginLeft: 200}}/>}
                        </div>
                        <div>
                            <div style={{marginLeft: 40, }}>      
                                <img src={"/images/from_ig_ig_profile_AD.webp"} alt="from instagram profile" style={{height: deviceBgHeight}} />
                            </div>
                        </div>
                        {<div style={{ marginLeft: 10, width: 274, height: deviceBgHeight, }}>      
                            <div style={{marginLeft: 14, position: 'relative', top: storyTopMargin}}>                                                  
                                <Player fromMaker={false} fromDemo={"top"} mini={true} storyId={"cafeupreality"} windowDimensions={{width: 244, height: storyHeight}} />
                            </div>
                            <img src="/images/transparent_framed.png" style={{position: "relative", top: 0, width: 274, height: deviceBgHeight, backgroundImage: "url('/images/transparent_framed.png')", backgroundSize: "274px " + deviceBgHeight + "px", pointerEvents: "none"}} />
                        </div>}                                                                                    
                    </div>
                </div>
                <div style={{marginTop: spaceBetweenFirstScreenAndSecond, display: "flex", flexDirection: "row",  justifyContent: "center", alignContent: "center", width: '100%', backgroundColor: "white"}}>
                    <div>
                        <h2 style={{marginTop: 60, width: 640}}>{t("InstantAdBenefit1")}</h2>
                        <div>
                            <br/>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <img src="/images/ic_check.png" />
                                <div style={{lineHeight: 1.6}}><font style={{fontSize: 18}}>{t("InstantAdSubBenefit11")}</font><br/><i>{t("InstantAdSubBenefit11det")}</i></div>
                            </div>
                            <br/>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <img src="/images/ic_check.png" />
                                <div style={{lineHeight: 1.6}}><font style={{fontSize: 18}}>{t("InstantAdSubBenefit12")}</font><br/><i>{t("InstantAdSubBenefit12det")}</i></div>
                            </div>
                            <br/>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <img src="/images/ic_check.png" />
                                <div style={{lineHeight: 1.6}}><font style={{fontSize: 18}}>{t("InstantAdSubBenefit13")}</font><br/><i>{t("InstantAdSubBenefit13det")}</i></div>
                            </div>
                        </div>
                    </div>
                    {<div style={{marginLeft: 32}}>
                        <div style={{backgroundColor: "#b2ede7", borderRadius: 20, padding: 32, display: "flex", flexDirection: "row", }}>
                            <ImageModal alt="stats page preview" src={language === "fr" ? "/images/demo_stats.webp" : "/images/demo_stats_en.webp"} style={{height: 400}} />
                        </div>
                    </div>}
                </div>                 
                <div style={{marginTop: 80, display: "flex", flexDirection: "row",  justifyContent: "center", alignContent: "center", width: '100%', backgroundColor: "white"}}>
                    <div>
                        <h2 style={{marginTop: 60, width: 640}}>{t("InstantAdBenefit2")}</h2>
                        <div>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <img src="/images/ic_check.png" />
                                <div>{t("InstantAdSubBenefit21")}</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <img src="/images/ic_check.png" />
                                <div>{t("InstantAdSubBenefit22")}</div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <img src="/images/ic_check.png" />
                                <div>{t("InstantAdSubBenefit23")}</div>
                            </div>
                        </div>
                    </div>
                    {<div style={{marginLeft: 32}}>
                        <div style={{backgroundColor: "#b2ede7", borderRadius: 20, padding: 32, display: "flex", flexDirection: "row", }}>
                            <ImageModal alt="story maker page preview" src={language === "fr" ? "/images/demo_builder.webp" : "/images/demo_builder_en.webp"} style={{height: 400}} />
                        </div>
                    </div>}
                </div>
                <div style={{marginTop: 80, display: 'flex', flexDirection: 'row', justifyContent: 'center', width: "100%"}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>                        
                        <MaterialButton style="filled" onClick={()=>{setShowCreate1Clic("ad_create1ClickBtm");analyticsManager.logOpenPopupTag(null, "ad_create1ClickBtm", true);}}  value={t("CTA")} /> 
                        <div style={{marginTop: 20}}/> 
                        <MaterialButton style="outlined-primary" onClick={()=>{analyticsManager.logHPTryMakerTag("bottom_ad");navigate("/maker");}}  value={t("InstantAdCTA")} />
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 40, width: "100%", backgroundColor: "#FFFFFF",}}>
                    <div style={{marginTop: 20, marginBottom: 100, width: 800, padding: 32, borderRadius: 20}}>
                        <Faq
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", backgroundColor: "#FFFFFF"}}>
                    <div style={{width: 800, padding: 32, borderRadius: 20,}}>
                        <div style={{display: "flex", flexDirection:"row", alignContent: "flex-start", alignItems: "flex-start", justifyContent: "flex-start"}}>
                            <img alt="Jacques, Product Manager" src="/images/jacques.png" width="80" />
                            <div style={{marginLeft: 32}}>
                                <div style={{fontSize: 26, fontWeight: "bold"}}>{t("AboutUs")}</div><br/>
                                {t("Bio")}<br/><br/><br/><br/><br/><br/><br/>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", backgroundColor: "#b2ede7"}}>
                    <div style={{textAlign: "center", marginTop: 20, marginBottom: 16, backgroundColor: "#b2ede7"}}>
                    <a onClick={()=>{analyticsManager.logReadCGUTag("home_page_ad");window.open("/terms/cgu.pdf", "_blank");}}>{t("Terms")}</a> - <a onClick={()=>{analyticsManager.logReadPrivacyTag("home_page_ad");window.open("/terms/charte_confidentialite.pdf", "_blank");}}>{t("Privacy")}</a>
                    </div>
                </div>
                
                <LoginDialog origin="home_page_ad" showLogin={showLogin !== false} customLoginButton={showLogin != false} title={loginTitle} resetLoginDialog={setShowLogin} onSuccess={(result)=>onLogin(result.user)}/>
                {showAckDisconnectedSnack()}
                {showAckAccountDeletedSnack()}
                {showErrorEmailLinkExpiredSnack()}
                {showErrorRegistrationLimitReachedSnack()}
                {showErrorSnack()}
                <Create1ClicDialog show={showCreate1Clic} closeDialog={setShowCreate1Clic} origin="ad_home_page"/>
            </div>
        );
    }
    else {
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: "100%", alignItems: 'center', alignContent: 'center', justifyContent: 'center', backgroundColor: 'white' }}>                
                <div style={{marginTop: 20, display: "flex", flexDirection: "row", alignItems: "center"}}><div style={{color: "#04d3bc", fontSize: 42, fontWeight: "bold"}}>UpReality </div> <div style={{fontSize: 26, marginTop:2}}>&nbsp;🌊</div></div>
                <br/>
                <div style={{width: 180, height: 1, background: "radial-gradient(#b2ede7, #ffffff)"}} />
                <br/>
                <div style={{paddingLeft: 16, paddingRight: 16}}><h2>{t("InstantAdHero1")}&nbsp;<div className="yellowParallelogram"><div className="textOverYellowParallelogram">{t("InstantAdHero2")}&nbsp;</div></div><br/>{t("InstantAdHero3")}</h2></div>                
                <div style={{fontSize: 18, paddingLeft: 16, paddingRight: 16}}>{t("InstantAdHero41")}{t("InstantAdHero42")}</div>                                
                <div style={{marginTop: 20, height: 576, display: 'flex', flexDirection: 'column', alignContent: 'flex-start',}}>
                    <div style={{marginLeft: 14, position: 'relative', top: 15}}>    
                        <Player fromMaker={false} fromDemo={"top"} mini={true} storyId={"cafeupreality"} windowDimensions={{width:244, height:540}} /> 
                    </div>
                    <img id="hp-phonebg-top" src="/images/transparent_framed.png" style={{position: "relative", top: 0, width:274, height:576, backgroundImage: "url('/images/transparent_framed.png')", backgroundSize: "274px 576px",}} />
                </div>                    
                <img src={"/images/from_ig_ig_profile_mobile_AD.webp"} width="300" style={{marginTop: 10, marginRight: 18}}/>                           
                <div style={{marginTop: 20}}/>          
                <MaterialButton style="filled" onClick={()=>{setShowCreate1Clic("ad_create1Click");analyticsManager.logOpenPopupTag(null, "ad_create1Click", true);}}  value={t("CTA")} />   
                <LineBreak/>             
                <MaterialButton style="outlined-primary" onClick={()=>{analyticsManager.logHPTryMakerTag("top_ad");navigate("/maker");}}  value={t("InstantAdCTA")} />                    
                <LineBreak/>
                <MaterialButton style="text"  onClick={()=>{analyticsManager.logOpenPopupTag(null, "home_ad_login", true);setShowLogin("login");setLoginTitle(t('Login'));}} value={t("Login")} />                                
                <div style={{marginTop: 40, width: "100%"}}>
                    <div style={{padding: 16}}>
                        <div style={{fontSize: 22, fontWeight: "bold", marginBottom: 10}}>{t("InstantAdBenefit1")}</div>                        
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10}}>
                            <img src="/images/ic_check.png" width="24"/>
                            <div>{t("InstantAdSubBenefit11")}</div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10}}>
                            <img src="/images/ic_check.png" width="24"/>
                            <div>{t("InstantAdSubBenefit12")}</div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <img src="/images/ic_check.png" width="24"/>
                            <div>{t("InstantAdSubBenefit13")}</div>
                        </div>                        
                    </div>
                </div>
                <div style={{marginTop: 40, width: "100%"}}>
                    <div style={{padding: 16}}>
                        <div style={{fontSize: 22, fontWeight: "bold", marginBottom: 10}}>{t("InstantAdBenefit2")}</div>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10}}>
                            <img src="/images/ic_check.png" width="24"/>
                            <div>{t("InstantAdSubBenefit21")}</div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10}}>
                            <img src="/images/ic_check.png" width="24"/>
                            <div>{t("InstantAdSubBenefit22")}</div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10}}>
                            <img src="/images/ic_check.png" width="24"/>
                            <div>{t("InstantAdSubBenefit23")}</div>
                        </div>
                    </div>
                </div>     
                <div style={{marginTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', width: "100%"}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>                        
                        <MaterialButton style="filled" onClick={()=>{setShowCreate1Clic("ad_create1ClickBtm");analyticsManager.logOpenPopupTag(null, "ad_create1ClickBtm", true);}}  value={t("CTA")} /> 
                        <div style={{marginTop: 20}}/> 
                        <MaterialButton style="outlined-primary" onClick={()=>{analyticsManager.logHPTryMakerTag("bottom_ad");navigate("/maker");}}  value={t("InstantAdCTA")} />
                    </div>
                </div>
                <div style={{marginTop: 40, padding: 16, backgroundColor: "#FFFFFF"}}>
                    <Faq
                        data={data}
                        styles={styles}
                        config={config}
                    />
                </div>
                <div style={{marginTop: 40, width: "100%", backgroundColor: "#FFFFFF",}}>
                    <div style={{fontSize: 26, width: "100%", fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "center", alignContent: 'center', alignItems: "center"}}>
                        {t("AboutUs")}
                        <img alt="Jacques, Product Manager" src="/images/jacques.png" width="80" style={{marginLeft: 16}} />
                    </div><br/> 
                    <div style={{width: "100%", display: "flex", flexDirection:"row", alignContent: "flex-start", alignItems: "flex-start", justifyContent: "flex-start"}}>                        
                        <div style={{marginLeft: 16, marginTop: 8, marginRight: 16, width: "100%", textAlign: "left"}}>
                            {t("Bio")}
                        </div>
                    </div>
                    <br/>                        
                </div>
                <br/>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", backgroundColor: "#b2ede7"}}>
                    <div style={{textAlign: "center", marginTop: 16, marginBottom: 16, backgroundColor: "#b2ede7"}}>
                        <a onClick={()=>{analyticsManager.logReadCGUTag("home_page_ad");window.open("/terms/cgu.pdf", "_blank");}}>{t("Terms")}</a> - <a onClick={()=>{analyticsManager.logReadPrivacyTag("home_page_ad");window.open("/terms/charte_confidentialite.pdf", "_blank");}}>{t("Privacy")}</a>
                    </div>
                </div>
                <LoginDialog origin="home_page_ad" showLogin={showLogin !== false} customLoginButton={showLogin != false} title={loginTitle} resetLoginDialog={setShowLogin} onSuccess={(result)=>onLogin(result.user)}/>
                {showAckDisconnectedSnack()}
                {showAckAccountDeletedSnack()}
                {showErrorEmailLinkExpiredSnack()}
                {showErrorRegistrationLimitReachedSnack()}
                {showErrorSnack()}
                <Create1ClicDialog show={showCreate1Clic} closeDialog={setShowCreate1Clic} origin="ad_home_page"/>
            </div>
        );
    }
}

export default InstantAdHP;