import './../../Player.css';
import React,{useContext, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Image} from 'react-native';
import { PlayerContext } from '../../PlayerContext';
import { darkerColor, getTextWidth } from '../../Utils';
import { useAnimate } from 'framer-motion';
import TodayIcon from '@mui/icons-material/Today';
import ShareIcon from '@mui/icons-material/Share';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { setStoryClicked1Time } from '../../Player';
import MaterialButton from '../../../maker/component/MaterialButton';

export const bookType = "book";
export const shareType = "share";
export const contactType = "contact";
export const applyType = "apply";

function PrimaryButton(props){
    const { t } = useTranslation();

    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
    const primaryColor = useContext(PlayerContext).get("primaryColor");
    const secondaryColor = useContext(PlayerContext).get("secondaryColor");    
    const defaultColor = "#FFFFFF";
    const highlight = useContext(PlayerContext).get("highlight");
    const setHighlight = useContext(PlayerContext).get("setHighlight");
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier");
    const setPlayInteractiveAnimation = useContext(PlayerContext).get("setPlayInteractiveAnimation");
    const [scope, animate] = useAnimate();
    
    let icon = "#";
    let textKey = "";
    let refBtnType = "BookButton";    
    if ((props.story.posBookingLink == null || props.story.posBookingLink === "none") /*&& props.story.orderingPlatforms == null*/ /*&& !props.story.posBookingByPhone*/ || props.story.posId === "ChIJC4g4KBNx5kcRH_z3vKUpnZw")
        refBtnType = "ContactButton";
    if (props.storyId === "cJU0Fnom8MTjmokacI3I" || props.storyId === "WX2KsZRK4SNKT9hnNIp0"){
        refBtnType = "ApplyButton";
    }    
    const maxTextWidth = Math.max(getTextWidth(t(refBtnType), 18 + "px Roboto condensed"), getTextWidth(t('ShareButton'), 18 + "px Roboto condensed"));    
    const buttonWidth = (8 + 8 + 16 + 4 + maxTextWidth + 8 + 8 - 10) * miniMultiplier;
    const buttonHeight = 36 * miniMultiplier;
    let x = storyDimensions.containerWidth - buttonWidth - 8 * miniMultiplier;
    let y = 0;
    let backgroundColor = "#FFFFFF";
    let textColor = "#000000";
    //alert(maxTextHeight);    

    switch (props.buttonType){
        case bookType: 
            icon = "/images/ic_today.png";        
            y = storyDimensions.containerHeight - 8 * miniMultiplier - buttonHeight - 8 * miniMultiplier - buttonHeight;
            textKey = "BookButton";
            backgroundColor = defaultColor;
            textColor = darkerColor(primaryColor, secondaryColor);
            break;
        case shareType: 
            icon = "/images/ic_share.png";
            y = storyDimensions.containerHeight - 8 * miniMultiplier - buttonHeight;
            textKey = "ShareButton";
            if (props.storyId === "cJU0Fnom8MTjmokacI3I" || props.storyId === "WX2KsZRK4SNKT9hnNIp0"){
                textKey = "ShareShareButton";
            }
            backgroundColor = primaryColor;
            textColor = secondaryColor;
            break;
        case contactType: 
            icon = "/images/ic_contact.png";
            y = storyDimensions.containerHeight - 8 * miniMultiplier - buttonHeight - 8 * miniMultiplier - buttonHeight;
            textKey = "ContactButton";
            backgroundColor = defaultColor;
            textColor = darkerColor(primaryColor, secondaryColor);
            break;
        case applyType: 
            icon = "/images/ic_apply.png";
            y = storyDimensions.containerHeight - 8 * miniMultiplier - buttonHeight - 8 * miniMultiplier - buttonHeight;
            textKey = "ApplyButton";
            backgroundColor = defaultColor;
            textColor = darkerColor(primaryColor, secondaryColor);
            break;
        default:
            break;
    }

    useEffect(() => {
        if (highlight != null){

            let elementId = null;
            if (highlight.includes("book") && props.buttonType === bookType){
                elementId = "primary-btn-book";                
            }
            else if (highlight.includes("share") && props.buttonType === shareType){                
                elementId = "primary-btn-share";                
            }
            else if (highlight.includes("contact") && props.buttonType === contactType){
                elementId = "primary-btn-contact";                
            }

            const element = document.getElementById(elementId);
            if (elementId != null && element != null){
                const animation = async () => {
                    await animate(element, { backgroundColor: "#FFFFFF80"}, {duration: 0.6});
                    if (element != null)
                        animate(element, { backgroundColor: backgroundColor}, {duration: 0.6});
                }                
                animation();
            }
        }
      }, [highlight]);

    function open(e){
        e.stopPropagation();        
        setStoryClicked1Time(true);
        setPlayInteractiveAnimation(false);
        props.open();
    }

    function getIcon(){
        switch (props.buttonType){
            case bookType: 
                return <TodayIcon sx={{width: 16 * miniMultiplier, height: 16 * miniMultiplier, paddingTop: 0.05 * miniMultiplier, marginRight: miniMultiplier !== 1 ? -0.4 : 0, color: textColor}} />;
            case shareType: 
                return <ShareIcon sx={{width: 16 * miniMultiplier, height: 16 * miniMultiplier, marginRight: miniMultiplier !== 1 ? -0.4 : 0, color: textColor}} />;
            case contactType: 
                return <ContactSupportIcon sx={{width: 16 * miniMultiplier, height: 16 * miniMultiplier, paddingTop: 0.0 * miniMultiplier, marginRight: miniMultiplier !== 1 ? -0.4 : 0, color: textColor}} />;
            case applyType: 
                return <AssignmentIndIcon sx={{width: 16 * miniMultiplier, height: 16 * miniMultiplier, paddingTop: 0.05 * miniMultiplier, marginRight: miniMultiplier !== 1 ? -0.4 : 0, color: textColor}} />;
            default:
                return null;
        }
    }

    return (                
        <div style={{position: "absolute", zIndex: 10, left: x, top: y}}>
            <MaterialButton id={"primary-btn-"+props.buttonType} style={props.buttonType === shareType ? "branded-filled" : "branded-white-filled"} sx={{position: "absolute", }} color={textColor} bgColor={backgroundColor} icon={getIcon()} value={t(textKey)} width={buttonWidth} height={buttonHeight} onClick={open} />
        </div>
    );
}

export default PrimaryButton;