import React, {useContext, useState} from 'react';
import {PlayerContext} from '../../PlayerContext';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { analyticsManager } from '../../..';
import LineBreak from '../../../maker/component/LineBreak';
import { browserLocales, checkMobile, printObj } from '../../Utils';
import { useTranslation } from 'react-i18next';
import { StickerContext } from '../../StickerContext';
import './../../Player.css';
import CloseButton from '../../side_menu/components/CloseButton';
import { useMediaQuery } from 'react-responsive';

function MealDetail(props){
    const { t } = useTranslation();
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const playMode = useContext(PlayerContext).get("playMode");
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier"); 
    const language = browserLocales(true)[0] === "en" ? "en" : "fr";
    const stickerContext = useContext(StickerContext);
    const textFont = props.story.menuStyle === "custom" ? stickerContext.get("menuTextFont") : null; 
    const textFontStyle = textFont ? {fontFamily: textFont} : {};
    const textColorStyle = {};
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    if (props.menu && props.story.menuStyle === "custom" && props.menu.textColor){
        textColorStyle.color = props.menu.textColor;
    }
    if (props.menu && props.story.menuStyle === "custom" && props.menu.detailTextColor){
        textColorStyle.color = props.menu.detailTextColor;
    }
    const priceColorStyle = {};
    if (props.menu && props.story.menuStyle === "custom" && props.menu.priceColor){
        priceColorStyle.color = props.menu.priceColor;
    }
    if (props.menu && props.story.menuStyle === "custom" && props.menu.detailPriceColor){
        priceColorStyle.color = props.menu.detailPriceColor;
    } 
    const descColorStyle = {};
    if (props.menu && props.story.menuStyle === "custom" && props.menu.descColor){
        descColorStyle.color = props.menu.descColor;
    }
    if (props.menu && props.story.menuStyle === "custom" && props.menu.detailDescColor){
        descColorStyle.color = props.menu.detailDescColor;
    } 
    
    const nutrients = props.pageEntry && props.pageEntry.nutritionalValue ? props.pageEntry.nutritionalValue[language].split("-") : null;
    
    function formatDescription(){
        if (!props.pageEntry.entrySubtitle) return;
        
        const descriptionChunks = props.pageEntry.entrySubtitle[language].split("<br>");

        const formattedDescription = descriptionChunks.map((chunk)=>{
            const idx = descriptionChunks.indexOf(chunk);
            if (idx > 0 && idx < descriptionChunks.length){ 
                return (<><br/><i>{chunk.trim()}</i></>);
            }
            else
                return chunk.trim();
        });

        return formattedDescription;
    }

    if (props.pageEntry != null){
        let additionalStyle = {};
        if (props.menu && props.story.menuStyle === "custom" && props.menu.backgroundColor){
            additionalStyle.backgroundColor = props.menu.backgroundColor;
        }
        if (props.menu && props.story.menuStyle === "custom" && props.menu.detailBackgroundColor){
            additionalStyle.backgroundColor = props.menu.detailBackgroundColor;
        }        

        return (
            <>
                <div className={"menu-detail-container" + ((checkMobile() || playMode === "demo") ? ' menu-detail-container-scroll-mobile' : ' menu-detail-container-scroll-desktop')} style={{position: 'absolute', zIndex: 30, width: '100%', height: '100%', background: '#FFFFFF', display: 'flex', flexDirection: 'column', ...additionalStyle}} onClick={(e)=>e.stopPropagation()} onTouchStart={(e)=>e.stopPropagation()} onTouchEnd={(e)=>e.stopPropagation()}>
                    {playMode === "preview" && !isTabletOrMobile && <div style={{width: 1, height: storyDimensions.containerHeight * 0.6, backgroundColor: '#b2ede7', position: 'absolute', left: 0, bottom: 0}} />}                
                    <img alt="thumbnail of a dish" src={props.pageEntry.entryImage} alt={props.pageEntry.entryTitle[language]} style={{width: '100%', height: storyDimensions.containerHeight * 0.4, objectFit: 'cover', pointerEvents: 'none',}}/>
                    <div style={{padding: 16 * miniMultiplier}}>
                        <div style={{fontSize: 18 * miniMultiplier, color: '#404040', ...textFontStyle, ...textColorStyle}}>{props.pageEntry.entryTitle[language]}</div>
                        <LineBreak/>
                        <div style={{whiteSpace: 'pre-line', textAlign: 'start', fontSize: 14 * miniMultiplier, fontStyle: 'italic', color: '#707070', ...textFontStyle, ...descColorStyle}}>{props.pageEntry.entrySubtitle && formatDescription()}</div>
                        <LineBreak/>
                        <div style={{textAlign: 'end', fontSize: 14 * miniMultiplier, fontStyle: 'italic', color: '#707070', ...textFontStyle, ...priceColorStyle}}>{props.pageEntry.entryPrice && props.pageEntry.entryPrice[language]}</div>                    
                        {props.pageEntry.nutritionalValue && 
                        <>
                            <LineBreak/>
                            <LineBreak/>
                            <LineBreak/>
                            <div style={{color: '#404040', ...textFontStyle, ...textColorStyle}}>{t('Nutrients')}</div> 
                            <LineBreak/>                       
                            <div style={{display: 'flex', flexDirection: "row", width: '100%', justifyContent: 'space-evenly'}}>                            
                                <div className='circle' style={{background: "#dff44f"}}>
                                    <div className='nutrients-text' style={{...textFontStyle}}>
                                        {nutrients[0].trim()}
                                    </div>
                                </div>
                                <div className='circle' style={{background: "#abfb78"}}>
                                    <div className='nutrients-text' style={{...textFontStyle}}>
                                        {nutrients[1].trim()}
                                    </div>
                                </div>
                                <div className='circle' style={{background: "#b5ffdc"}}>
                                    <div className='nutrients-text' style={{...textFontStyle}}>
                                        {nutrients[2].trim()}
                                    </div>
                                </div>    
                            </div>                          
                            <div style={{marginTop: 10, display: 'flex', flexDirection: "row", width: '100%', justifyContent: 'space-evenly'}}>
                                <div className='circle' style={{background: "#caa593"}}>
                                    <div className='nutrients-text' style={{...textFontStyle}}>
                                        {nutrients[3].trim()}
                                    </div>
                                </div>
                                <div className='circle' style={{background: "#bae9b3"}}>
                                    <div className='nutrients-text' style={{...textFontStyle}}>
                                        {nutrients[4].trim()}
                                    </div>                               
                                </div>                       
                            </div>
                        </>}
                    </div>                           
                </div>
                <div style={{position: 'absolute', zIndex: 30, pointerEvents: "auto", top: storyDimensions.containerHeight - 48 * miniMultiplier - 8 * miniMultiplier, left: storyDimensions.containerWidth - 4 * miniMultiplier - 48 * miniMultiplier - 8 * miniMultiplier, }}>
                    <CloseButton onClick={(e) => props.close(e)} color={props.closeMenuColor}/>
                </div> 
            </>
        );
    }
    else return null;
}

export default MealDetail;