import React, {useState, useEffect, useContext} from 'react';
import Autocomplete from "react-google-autocomplete";
import { getFirestore, doc, updateDoc, deleteField} from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import { browserLocales, printObj, checkMobile, reduceMusicFileName, isVideo, isImg, getFileFormat, getFileNameFromUrl, fetchImageSize } from '../player/Utils';
import {MakerContext} from "./MakerContext";
import MaterialButton from './component/MaterialButton';
import TextField from '@mui/material/TextField';
import { useMediaQuery } from 'react-responsive';
import MobileMenu from './component/MobileMenu';
import {AppContext} from '../AppContext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { analyticsManager, functions } from '..';
import { resetStoryInDB } from './MenuController';
import { CHECK_MEDIA_CONTENT_REPLACE, checkMediaContent, cleanMakerQueryString, deleteExistingMediaContent, deleteFSThumbnailImg, deleteStoryMediaContent, deleteThumbnailImg, getAvatarFromInstagram, getRestaurantAlias, MEDIA_TYPE_AVATAR, MEDIA_TYPE_MUSIC, MEDIA_TYPE_STORE_FRONT, MEDIA_TYPE_STORE_FRONT_FS_TN, MEDIA_TYPE_STORE_FRONT_TN, saveAvatarUrl, saveInstagramUserName, saveMusicUrl, uploadMediaContent } from './MakerController';
import MobileBottomBar from './component/MobileBottomBar';
import * as Sentry from "@sentry/react";
import ContentPickerDialog from './component/ContentPickerDialog';
import { httpsCallable } from "firebase/functions";
import LineBreak from './component/LineBreak';

// for some reason story is not accessible from the Autocomplete callback, hack to access the story id
var chooseRestaurantStoryId = null;

function ChooseRestaurantForm(props) { 
            
    const [url, setUrl] = useState(null);  
    const [showInfoPreview, setShowInfoPreview] = useState(false);  
    const [showPicker, setShowPicker] = useState(false); 
    const setFeatureReset = useContext(MakerContext).get("setFeatureReset");
    const setResetStickerOutOfScreen = useContext(MakerContext).get("setResetStickerOutOfScreen"); 
    const fbAccessToken = useContext(MakerContext).get("fbAccessToken");  
    const instagramAccountId = useContext(MakerContext).get("instagramAccountId");
    const setInstagramAccountId = useContext(MakerContext).get("setInstagramAccountId");  
    const setInstagramLoginType = useContext(MakerContext).get("setInstagramLoginType");         
    const setFbAccessToken = useContext(MakerContext).get("setFbAccessToken");
    const story = useContext(MakerContext).get("story");
    
    const storyId = useContext(MakerContext).get("storyId");
    const baseStory = useContext(MakerContext).get("baseStory");
    const setActiveForm = useContext(MakerContext).get("setActiveForm");
    const setHighlight = useContext(MakerContext).get("setHighlight");      
    const fbResponse = useContext(AppContext).get("fbResponse");
    const setResetAudio = useContext(MakerContext).get("setResetAudio");
    const videoLoaded = useContext(MakerContext).get("videoLoaded");   
    const setVideoLoaded = useContext(MakerContext).get("setVideoLoaded");
    const reservable = useContext(MakerContext).get("reservable");
    const setReservable = useContext(MakerContext).get("setReservable");
    const setRestaurantPhone = useContext(MakerContext).get("setRestaurantPhone");    
    const instagramLoginType = useContext(MakerContext).get("instagramLoginType");

    const activePopup = useContext(MakerContext).get("activePopup");
    const setActivePopup = useContext(MakerContext).get("setActivePopup");

    const [bypassConnectToIGForAvatar, setBypassConnectToIGForAvatar] = useState(false);
    const [bypassContentPickerLogin, setBypassContentPickerLogin] = useState(false);   

    const [videoLoadedDuringSession, setVideoLoadedDuringSession] = useState(false);

    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const windowDimensions = useContext(MakerContext).get("windowDimensions");
    const stickerMoveAreaWidth = isTabletOrMobile ? windowDimensions.width : 360.0;
    const stickerMoveAreaHeight = isTabletOrMobile ? windowDimensions.height : makerDesktopHeight;

    const { t } = useTranslation();
    
    chooseRestaurantStoryId = story != null ? storyId : null;

    async function updatePosId(place){            
        await resetStoryInDB(chooseRestaurantStoryId, place.place_id);
        setTimeout(()=>{setHighlight("restaurantInfos book share contact hours reviews location")}, 1500);     
    } 

    useEffect(() => {           
        if (story != null && story.posId != null && reservable == null)
            initAutocomplete(story, story.posId);         
      }, [story && story.posId]); 
    
    useEffect(() => {
        analyticsManager.logMakerScreenViewTag();
    }, []);       

    function initAutocomplete(story, posId){        
        // TODO make php api works on localhost
        const language = browserLocales(true)[0] === "fr" ? "fr" : "en";        
        fetch("https://places.googleapis.com/v1/places/" + posId + "?fields=formattedAddress,displayName,primaryTypeDisplayName,addressComponents,nationalPhoneNumber,googleMapsUri,reservable,websiteUri&languageCode=" + language + "&key=" + process.env.REACT_APP_PLACE_DETAIL_API_KEY)
            .then(response => response.json())
            .then(data => {                                               
                if (data != null){
                    const place = data;                       
                    if (place.nationalPhoneNumber != null && place.nationalPhoneNumber.length !== 0 && story.posPhone == null){                          
                        setRestaurantPhone(place.nationalPhoneNumber);                        
                    }                                        
                    setUrl(place.googleMapsUri);
                    setReservable(place.reservable); 
                    // Only the first call     
                    if (story.posName == null){                  
                        const citySearch = place.addressComponents.find((component)=>component.types.includes("locality"));
                        const city = citySearch != null ? citySearch.shortText : "";
                        const countrySearch = place.addressComponents.find((component)=>component.types.includes("country"));
                        const country = countrySearch != null ? countrySearch.shortText : "";
                        analyticsManager.logChooseRestaurantTag(storyId, city, country, place.reservable);     

                        updateStoryFromPlace(place.displayName.text, place.reservable, city, country, place.websiteUri, place.primaryTypeDisplayName && place.primaryTypeDisplayName.text);
                        
                        if (isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous)
                            setTimeout(()=>setShowInfoPreview(true), 2000);
                    }
                }
            })
            .catch((error) => {                
                Sentry.captureMessage(printObj(error));                
                });
    }

    useEffect(() => {
        if ((activePopup === MEDIA_TYPE_AVATAR || activePopup === MEDIA_TYPE_STORE_FRONT) && props.show){    
            if (activePopup === MEDIA_TYPE_AVATAR){
                setShowPicker(MEDIA_TYPE_AVATAR);
                setBypassConnectToIGForAvatar(true);
            }   
            else if (activePopup === MEDIA_TYPE_STORE_FRONT){
                setShowPicker(activePopup);
                analyticsManager.logOpenPopupTag(storyId, "content_picker_" + activePopup, true);
                setBypassContentPickerLogin(true);
            }
                        
            setActivePopup(null);            
            if (instagramLoginType === "ig")
                cleanMakerQueryString(story.baseStoryId != null ? story.baseStoryId : storyId);
        }
      }, [activePopup && props.show]);

    async function handleOnFocusOut(e){
        const db = getFirestore();        
        const docRef = doc(db, "stories", chooseRestaurantStoryId);
        const update = {
            savedTime: new Date().getTime(),
        };        

        switch(e.target.id) {
            case "restaurantArea":
                if (e.target.value === story.posArea) return;
                update.posArea = e.target.value;
                analyticsManager.logChooseNeighborhoodTag(storyId, e.target.value, e.target.value !== "");
        }

        await updateDoc(docRef, update);

        if (e.relatedTarget != null && e.relatedTarget.id === "theme-btn"){
            analyticsManager.logMyRestaurantScreenViewTag(storyId);
            setActiveForm("choose-restaurant");
        }
    }

    async function updateStoryFromPlace(restaurantName, reservable, city, country, website, type){
        const restaurantAlias = await getRestaurantAlias(restaurantName, city, story, storyId);
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const previousAlias = story.alias != null ? story.alias : (story.baseStoryId != null ? story.baseStoryId : storyId);
        const update = {    
            posName: restaurantName, 
            city: city,
            country: country,
            alias: restaurantAlias,    
            aliasLowerCase: restaurantAlias.toLowerCase(),
            previousAlias: previousAlias,
            previousAliasLowerCase: previousAlias.toLowerCase(),
            storyLink: process.env.REACT_APP_HOST + "/p/" + restaurantAlias,     
            posBookingByPhone: reservable != null && reservable === true,            
            savedTime: new Date().getTime(),
        };   
        
        if (website)
            update.website = website;

        if (reservable)                 
            update.posBookingLink = "googlemaps";

        if (type)
            update.posType = type;

        await updateDoc(docRef, update);
    }  
    
    useEffect(() => {
        if (videoLoaded){
            const videoElement = document.getElementsByTagName("video")[0];
            updateVideoDimensions(videoElement);            
            setVideoLoaded(null);
        }        
    }, [videoLoaded]);

    async function updateVideoDimensions(videoElement){        
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);    
        const update = {
            width: videoElement.videoWidth,
            height: videoElement.videoHeight,
        };
        
        if (stickerMoveAreaHeight / stickerMoveAreaWidth > videoElement.videoHeight / videoElement.videoWidth) {
            update.dimWithOffset = "width";
            let trueWidthOnVideoFormat = Math.round(stickerMoveAreaWidth * videoElement.videoHeight / stickerMoveAreaHeight);
            update.offsetValue = videoElement.videoWidth - trueWidthOnVideoFormat;           
        } else {
            update.dimWithOffset = "height";
            let trueWidthOnVideoFormat = stickerMoveAreaHeight * videoElement.videoWidth / stickerMoveAreaWidth;
            update.offsetValue = Math.round(videoElement.videoHeight - trueWidthOnVideoFormat);           
        }
        
        if (videoLoadedDuringSession){
            if (story.virtualTourCenterXpct != null){
                update.virtualTourCenterXpct = 50;
                update.virtualTourCenterYpct = 25;
            }
            if (story.mediasCenterXpct != null){
                update.mediasCenterXpct = 50;
                update.mediasCenterYpct = 60;
            }  
            setResetStickerOutOfScreen(true);  
        }         
        await updateDoc(docRef, update);
    }

    function showStoreFrontDelete(){
        if (story != null && (story.imageRef != null || story.videoRef != null)){
            return (
                <MaterialButton style="text" onClick={deleteStoreFront} value="X" />
            );
        }
        else return null;
    }

    async function deleteStoreFront(){
        analyticsManager.logChooseStoreFrontTag(storyId, "store_front", story.imageRef != null ? "photo" : "video", false);
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_STORE_FRONT).then(()=>deleteStoreFrontField()); 
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_STORE_FRONT_TN).then(()=>deleteThumbnailImg(storyId));
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_STORE_FRONT_FS_TN).then(()=>deleteFSThumbnailImg(storyId));        
        
        async function deleteStoreFrontField(){
            const db = getFirestore();            
            const docRef = doc(db, "stories", storyId);
            // TODO recalcul width, weight, dimoffset et offsetvalue pour le placeholder
            const update = {                
                width: 460, // placeholder
                height: 1024, // placeholder
                savedTime: new Date().getTime(),
            };   
            
            if (stickerMoveAreaHeight / stickerMoveAreaWidth > update.height / update.width) {
                update.dimWithOffset = "width";
                let trueWidthOnVideoFormat = Math.round(stickerMoveAreaWidth * update.height / stickerMoveAreaHeight);
                update.offsetValue = update.width - trueWidthOnVideoFormat;           
            } else {
                update.dimWithOffset = "height";
                let trueWidthOnVideoFormat = stickerMoveAreaHeight * update.width / stickerMoveAreaWidth;
                update.offsetValue = Math.round(update.height - trueWidthOnVideoFormat);           
            }

            if (story.virtualTourCenterXpct != null){
                update.virtualTourCenterXpct = 50;
                update.virtualTourCenterYpct = 25;
            }
            if (story.mediasCenterXpct != null){
                update.mediasCenterXpct = 50;
                update.mediasCenterYpct = 60;
            } 

            setResetStickerOutOfScreen(true);
            
            if (story.imageRef != null){
                update.imageRef = deleteField();
            }
            else if (story.videoRef != null){
                update.videoRef = deleteField();
            }
            await updateDoc(docRef, update);
        }
    }
    
    function showAvatarDeleteButton(){
        if (story.avatarUrl != null && story.avatarUrl !== ""){
            return(
                <div style={{cursor: 'pointer', background: "#FFFFFF", borderRadius: 20, position: 'absolute', bottom: 4, right: 4, padding: 2, width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=>deleteStoryMediaContent(story, storyId, story.avatarUrl, baseStory != null ? baseStory.avatarUrl : null).then(()=>{analyticsManager.logChooseMyLogoTag(storyId, "my_restaurant_from", "photo", false);setAvatarUrl("");})}>x</div>
            );
        }
        else return null;
    }

    async function setStoreFrontUrl(url, source, type){
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);

        const update = {
            savedTime: new Date().getTime(),
        };
        const fileFormat = getFileFormat(getFileNameFromUrl(url));
        if (isImg(fileFormat)){
            analyticsManager.logChooseStoreFrontTag(storyId, source, type, true);
            update.imageRef = url;
            update.videoRef = deleteField()
            
            const dimensions = await fetchImageSize(url);
            if (dimensions != null){
                // TODO global variables
                if (stickerMoveAreaHeight / stickerMoveAreaWidth > dimensions.height / dimensions.width) {
                    update.dimWithOffset = "width";
                    let trueWidthOnVideoFormat = Math.round(stickerMoveAreaWidth * dimensions.height / stickerMoveAreaHeight);
                    update.offsetValue = dimensions.width - trueWidthOnVideoFormat;
                } else {
                    update.dimWithOffset = "height";
                    let trueWidthOnVideoFormat = stickerMoveAreaHeight * dimensions.width / stickerMoveAreaWidth;
                    update.offsetValue = Math.round(dimensions.height - trueWidthOnVideoFormat);
                }
                if (story.virtualTourCenterXpct != null){
                    update.virtualTourCenterXpct = 50;
                    update.virtualTourCenterYpct = 25;
                }
                if (story.mediasCenterXpct != null){
                    update.mediasCenterXpct = 50;
                    update.mediasCenterYpct = 60;
                } 

                setResetStickerOutOfScreen(true);

                update.width = dimensions.width;
                update.height = dimensions.height;
            }            
            setShowPicker(false);
        }
        else if (isVideo(fileFormat)){
            setVideoLoadedDuringSession(true);
            analyticsManager.logChooseStoreFrontTag(storyId, source, type, true);
            update.videoRef = url;
            update.imageRef = deleteField();
            update.width = deleteField();
            update.height = deleteField();
            update.dimWithOffset = deleteField();
            update.offsetValue = deleteField();
            // TODO width, height, dimWithOffset et offsetValue à calculer par batch        
        }
        // = the uploaded content returns a "" url, should never happen
        /*else if (url === ""){
            analyticsManager.logChooseStoreFrontTag(storyId, source, type, false);
            update.videoRef = deleteField();
            update.imageRef = deleteField();
            update.width = stickerMoveAreaWidth;// -> err 360, see delete store front
            update.height = stickerMoveAreaHeight;// -> err 800, see delete store front
            update.dimWithOffset = "width";
            update.offsetValue = 0;
            setShowPicker(false);
        }*/
        await updateDoc(docRef, update);
        if (url !== ""){
            setAvatarAndColors();
        }
    }

    function setAvatarAndColors(){ 
                 
        if (instagramLoginType === "fb" && story.avatarUrl == null){     
            // TODO mutualize with content picker dialog      
            getAvatarFromInstagram(fbAccessToken, instagramAccountId)
                .then((profile_picture_url)=> 
                    new Promise((resolve, reject) => {
                        fetch(profile_picture_url)
                            .then(res => res.blob())
                            .then(blob => {                                                       
                                blob.name = getFileNameFromUrl(profile_picture_url);                                
                                return blob;                  
                            })
                            .then((response)=> resolve(response))
                            .catch((error)=> reject(error));
                    })                
                )
                .then((blob)=>
                    new Promise((resolve, reject) => {
                        checkMediaContent(blob, story, storyId, baseStory, MEDIA_TYPE_AVATAR, ()=>{}, CHECK_MEDIA_CONTENT_REPLACE)
                            .then(()=> uploadMediaContent(story, storyId, blob, MEDIA_TYPE_AVATAR, "instagram"))
                            .then((mediaUrl)=> resolve(mediaUrl))
                            .catch((error)=> reject(error));
                    }))
                .then((mediaUrl)=> setAvatarUrl(mediaUrl))
                .then((mediaUrl)=>{                          
                    analyticsManager.logChooseMyLogoTag(storyId, "connect_instagram", "photo", true);                                                          
                    if (story.primaryColor === "#2FC4B5" && mediaUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){                    
                        analyticsManager.logChoosePrimaryColorTag(storyId, "connect_instagram");                                           
                        const detectSetColors = httpsCallable(functions, 'detectSetColors');
                        detectSetColors({ storyId: storyId })
                            .then(()=>setTimeout(()=>{setHighlight("avatar book share contact")}, 1000))
                            /*.catch((error)=>log(printObj(error)))*/;
                    }
                    else 
                        setTimeout(()=>{setHighlight("avatar book contact");}, 1000);
                })
                .catch((error)=>{  
                    setFbAccessToken(null);
                    setInstagramAccountId(null);
                    setInstagramLoginType(null);
                    Sentry.captureMessage(printObj(error));
                });            
        }
        else if (instagramLoginType === "ig"){                
            fetch("https://graph.instagram.com/me?fields=id,username,profile_picture_url&access_token=" + fbAccessToken)
                .then((response) => response.json())                    
                .then((response)=>{
                    saveInstagramUserName(storyId, response.username, response.username);
                    setTimeout(()=>{setHighlight("book contact");}, 1000);
                    return response.profile_picture_url;
                })
                .then((profile_picture_url)=> 
                    new Promise((resolve, reject) => {
                        fetch(profile_picture_url)
                            .then(res => res.blob())
                            .then(blob => {                                                       
                                blob.name = getFileNameFromUrl(profile_picture_url);                                
                                return blob;                  
                            })
                            .then((response)=> resolve(response))
                            .catch((error)=> reject(error));
                    })                
                )
                .then((blob)=>
                    new Promise((resolve, reject) => {
                        checkMediaContent(blob, story, storyId, baseStory, MEDIA_TYPE_AVATAR, ()=>{}, CHECK_MEDIA_CONTENT_REPLACE)
                            .then(()=> uploadMediaContent(story, storyId, blob, MEDIA_TYPE_AVATAR, "instagram"))
                            .then((mediaUrl)=> resolve(mediaUrl))
                            .catch((error)=> reject(error));
                    }))
                .then((mediaUrl)=> setAvatarUrl(mediaUrl))
                .then((mediaUrl)=>{                          
                    analyticsManager.logChooseMyLogoTag(storyId, "connect_instagram", "photo", true);                                                          
                    if (story.primaryColor === "#2FC4B5" && mediaUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){                    
                        analyticsManager.logChoosePrimaryColorTag(storyId, "connect_instagram");                                           
                        const detectSetColors = httpsCallable(functions, 'detectSetColors');
                        detectSetColors({ storyId: storyId })
                            .then(()=>setTimeout(()=>{setHighlight("avatar book share contact")}, 1000))
                            /*.catch((error)=>log(printObj(error)))*/;
                    }
                    else 
                        setTimeout(()=>{setHighlight("avatar book contact");}, 1000);
                })
                .catch((error)=>{  
                    setFbAccessToken(null);
                    setInstagramAccountId(null);
                    setInstagramLoginType(null);
                    Sentry.captureMessage(printObj(error));
                });
        }
    }

    async function onAvatarPicked(url, source, type){
        analyticsManager.logChooseMyLogoTag(storyId, source, "photo", true);
        await setAvatarUrl(url);        
        if (story.primaryColor != null && story.primaryColor.toLowerCase() === "#2fc4b5" && url.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){
            analyticsManager.logChoosePrimaryColorTag(storyId, "choose_avatar");                                           
            const detectSetColors = httpsCallable(functions, 'detectSetColors');
            detectSetColors({ storyId: storyId })
                .then(()=>setTimeout(()=>{setHighlight("avatar book share contact");}, 1000))                
        }
    }

    async function setAvatarUrl(url){
        await saveAvatarUrl(storyId, url);
        setTimeout(()=>{setHighlight("avatar");}, 1000);

        return url;
    } 

    async function onMusicPicked(url, source, type){
        analyticsManager.logChooseMyMusicTag(storyId, source, "music", true);
        setResetAudio(true);
        
        await setMusicUrl(url);        
    }

    async function setMusicUrl(url){
        await saveMusicUrl(storyId, url);        

        return url;
    } 

    function showMusicDeleteButton(){
        if (story.musicUrl != null && story.musicUrl !== ""){
            return(<div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                    {story.musicUrl !== null && reduceMusicFileName(story.musicUrl)}
                    <MaterialButton style="text" reducedPadding={true} onClick={()=>deleteStoryMediaContent(story, storyId, story.musicUrl, baseStory != null ? baseStory.musicUrl : null).then(()=>{analyticsManager.logChooseMyMusicTag(storyId, "my_restaurant_from", "music", false);setMusicUrl("");})} value="X"/>                        
                </div>
            );
        }
        else return null;
    }

    function showFormOrLogin(){     
        const isMobileBottomBarDisplayed = isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous;   
        const screenWidthToMakerForm = isTabletOrMobile ? 0 : 360;                   
        if ((story == null || story.posId == null) && !checkMobile()) {            
            
            return (            
                <>
                    <div style={{marginTop: 20, textAlign: 'center'}}> 
                        <img src="/images/arc_arrow.png" width="160px"/>                                      
                    </div>   
                    <div style={{position: 'absolute', paddingLeft: 16, paddingRight: 16, bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), right: screenWidthToMakerForm+16, fontSize: 14, fontStyle: 'italic'}}>
                        {t("MultipleRestaurantsHelp1")}<a href={"mailto:contact@upreality.store?subject=" + t("MultipleRestaurantsMailSubject")}>{t("MultipleRestaurantsHelp2")}</a>
                    </div>                                      
                </>
            );                        
        }
        else if (story != null && story.posId != null){            
            const additionalStyle = isMobileBottomBarDisplayed ? {overflowY: "scroll", height: windowDimensions.height - 56} : {};

            let avatarUrl = "/images/avatar_placeholder.png";
            if (story != null && story.avatarUrl != null && story.avatarUrl !== ""){
                avatarUrl = story.avatarUrl;
            }

            let onSuccess = null;
            let mediaContentType = null;
            switch (showPicker){
                case MEDIA_TYPE_STORE_FRONT:
                    onSuccess = setStoreFrontUrl;                
                    break;
                case MEDIA_TYPE_MUSIC:
                    mediaContentType = story.musicUrl;
                    onSuccess = onMusicPicked;
                    break;
                case MEDIA_TYPE_AVATAR:
                    mediaContentType = story.avatarUrl;
                    onSuccess = onAvatarPicked;
                    break;    
            }            

            return (
                <div style={{...additionalStyle}}>   
                    <div style={{fontSize: 28}}>{t('MyRestaurant')}</div>
                    <br/>
                    <div style={{fontSize: 20}}>{t('RestaurantStoreFront')}</div><br/>
                    <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                        <MaterialButton style="outlined-tonal" onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_store_front", true);setShowPicker(MEDIA_TYPE_STORE_FRONT); setFeatureReset(true);}} value={t('ChooseMyStoreFront')} />
                        {showStoreFrontDelete()}
                    </div><br/>
                    <div style={{display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'space-between'}}>
                        <MaterialButton style="outlined-tonal" onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_music", true);setShowPicker(MEDIA_TYPE_MUSIC);}} value={t('ChooseMyMusic')} />
                        <div>                                
                            {showMusicDeleteButton()}                            
                        </div>                        
                    </div>  
                    <br/>                    
                    <div style={{fontSize: 20, marginTop: 20}}>{t('YourInfos')} </div>                        
                    <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                        <MaterialButton style="outlined-tonal" onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_avatar", true);setShowPicker(MEDIA_TYPE_AVATAR);}} value={t('ChooseMyLogo')} />
                        <div style={{marginLeft: 100, position: 'relative'}}>
                            <img alt="restaurant's avatar" src={avatarUrl} className="maker-logo-container"/>
                            {showAvatarDeleteButton()}                            
                        </div>                        
                    </div>      
                    <br/>          
                    <TextField id='restaurantArea' type='text' style={{width: '100%'}} defaultValue={story.posArea != null ? story.posArea : ""} placeholder={t('PlaceholderRestaurantArea')} onBlur={(e)=>{setTimeout(()=>setHighlight("address"), 1000);handleOnFocusOut(e);}} /><br/><br/>                    
                    <TextField id='restaurantChatMsg' type='text' style={{width: '100%'}} defaultValue={story.chatMsg != null ? story.chatMsg : ""} placeholder={t('PlaceholderChatMsg')} onBlur={(e)=>onChatBlur(e)}/><br/><br/>                                                                      
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), right: screenWidthToMakerForm+16, position: 'absolute'}}>
                        <MaterialButton id="stickers-btn" style="filled-tonal" onClick={() => {analyticsManager.logChooseStickersScreenViewTag(storyId);setActiveForm("choose-stickers");}} value={t('ChooseRestaurantToStickers')}/>
                    </div>  
                    <MobileMenu storyId={storyId} story={story}/> 
                    <ContentPickerDialog showContentPickerDialog={showPicker} setShowContentPickerDialog={setShowPicker} mediaContentType={showPicker} storyId={storyId} story={story} baseStory={baseStory} storyContentField={mediaContentType} withProgress={false} onSuccess={onSuccess} bypassFromIG={bypassConnectToIGForAvatar || bypassContentPickerLogin} resetBypassFromIg={bypassConnectToIGForAvatar && setBypassConnectToIGForAvatar || bypassContentPickerLogin && setBypassContentPickerLogin} />                         
                    {isMobileBottomBarDisplayed && <div style={{height: 100}}/>}
                </div>
            );                     
        }
        else return null;
    } 

    async function onChatBlur(e){
        analyticsManager.logChooseTaglineTag(storyId, e.target.value !== "");
        await updateChatMsg(e);
        setTimeout(()=>setHighlight("chat"), 1000);
        if (e.relatedTarget != null && e.relatedTarget.id === "stickers-btn"){
            analyticsManager.logChooseStickersScreenViewTag(storyId);
            setActiveForm("choose-stickers");
        }
    }

    async function updateChatMsg(e){
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const update = {
            chatMsg: e.target.value,
            savedTime: new Date().getTime(),
        };                        

        await updateDoc(docRef, update);
    }        

    function showAlertModifySnack(){
        if (props.firstDisplayWarning){            
            return (
                <Snackbar open={props.firstDisplayWarning} autoHideDuration={6000} onClose={()=>props.setFirstDisplayWarning(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}  sx={{ width: "90%" }}>
                    <Alert onClose={()=>props.setFirstDisplayWarning(false)} severity="warning" sx={{ width: '100%' }}>     
                    {t('ModifyStoryWarning')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    function showInfoPreviewSnack(){
        if (showInfoPreview){            
            return (
                <Snackbar open={showInfoPreview} autoHideDuration={6000} onClose={()=>setShowInfoPreview(false)} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowInfoPreview(false)} severity="info">     
                    {t('InfoPreview')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }    
    
    if (props.show) {
        const isMobileBottomBarDisplayed = isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous; 

        return(
            <div>
                <div style={{padding: 16}}>
                    {!(story && story.posId) && <div style={{fontSize: 28}}>
                        {t('ChooseRestaurant')}
                        <br/>
                        {(fbResponse == null || fbResponse.isAnonymous) && (story == null || story.posId == null) && 
                            <>                                
                                <div style={{fontSize: 14, fontStyle: 'italic', marginTop: 20}}>{t('PlaceholderAutocompleaterHelp')}</div>                                
                            </>
                        }
                        <Autocomplete         
                            id="autocompleater"                                       
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            onPlaceSelected={(place) => updatePosId(place)}
                            style={{width: '100%' }}
                            placeholder={t('PlaceholderAutocompleater')}
                            options={{
                                types: ["restaurant"], componentRestrictions: {country: "fr"}
                            }}  
                            disabled={story != null && story.posId != null}                      
                            />    
                        {checkMobile() &&
                            <div style={{position: 'absolute', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), fontSize: 14, fontStyle: 'italic'}}>
                                {t("MultipleRestaurantsHelp1")}<a href={"mailto:contact@upreality.store?subject=" + t("MultipleRestaurantsMailSubject")}>{t("MultipleRestaurantsHelp2")}</a>
                            </div>
                        }                    
                    </div> 
                    }               
                    {showFormOrLogin()}                        
                    {showAlertModifySnack()}    
                    {showInfoPreviewSnack()}                
                </div>                
                <MobileBottomBar currentTab="0" storyId={storyId} />
            </div>
        );
    }
    else return null;
}

export default ChooseRestaurantForm;