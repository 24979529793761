import React, {useState, useContext, useEffect, useRef} from 'react';
import {AppContext} from "../../AppContext";
import {MakerContext} from "../MakerContext";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import MaterialButton from '../component/MaterialButton';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import {Image} from 'react-native';
import { MEDIA_TYPE_AVATAR, checkMediaContent, uploadMediaContent, MEDIA_TYPE_STORE_FRONT, MEDIA_TYPE_INSIDE_PREVIEW, CHECK_MEDIA_CONTENT_REPLACE, CHECK_MEDIA_CONTENT_ADD, MEDIA_TYPE_MENU_PREVIEW, getAvatarFromInstagram, getMediaContentsFromInstagram, MEDIA_TYPE_DAY_MENU, MEDIA_TYPE_MENU_FR, MEDIA_TYPE_MUSIC, MEDIA_TYPE_MENU_EN, sendMenuOptimizedEmail} from '../MakerController'
import { getFileExtensionFromMimeType, getFileFormat, getFileNameFromUrl, isAudio, isIOS, isImg, isSafari, isSupportedByPlayer, isVideo, printObj, sleep, browserLocales, isAndroid, isFirefox} from '../../player/Utils';
import { getFirestore, doc, updateDoc, getDoc, deleteField} from "firebase/firestore";
import LoginDialog from '../LoginDialog';
import { DialogContent } from '@mui/material';
import { analyticsManager } from '../..';
import LineBreak from './LineBreak';
import useInterval from './UseInterval';
import * as Sentry from "@sentry/react";
import ColorPicker from './ColorPicker';
import MenuStylePreview from './MenuStylePreview';
import { Switch } from '@mui/material';

function ContentPickerDialog(props) {

    const windowDimensions = useContext(MakerContext).get("windowDimensions");

    const fbAccessToken = useContext(MakerContext).get("fbAccessToken");
    const setFbAccessToken = useContext(MakerContext).get("setFbAccessToken");
    const setInstagramAccountId = useContext(MakerContext).get("setInstagramAccountId");
    const setInstagramLoginType = useContext(MakerContext).get("setInstagramLoginType");    
    const menuEmailSent = useContext(MakerContext).get("menuEmailSent");
    const setMenuEmailSent = useContext(MakerContext).get("setMenuEmailSent");
    const story = useContext(MakerContext).get("story");
    const storyId = useContext(MakerContext).get("storyId");
    const baseStory = useContext(MakerContext).get("baseStory");
    const setHighlight = useContext(MakerContext).get("setHighlight");
    const setFeatureReset = useContext(MakerContext).get("setFeatureReset"); 

    const [dialogErrorMsg, setDialogErrorMsg] = useState(null);
    const fbResponse = useContext(AppContext).get("fbResponse"); 
    const [showProgress, setShowProgress] = useState(false);
    const [showImagePicker, setShowImagePicker] = useState(null);
    const [igPictures, setIgPictures] = useState(null); 
    const [selectedContentsTemp, setSelectedContentsTemp] = useState([]);
    const [previousPage, setPreviousPage] = useState(null);
    const [nextPage, setNextPage] = useState(null);

    const [showMenuStyle, setShowMenuStyle] = useState(false);
    const [menuStyleTemp, setMenuStyleTemp] = useState(null);
    const [menuColorTemp, setMenuColorTemp] = useState(null);
    const [refreshStyle, setRefreshStyle] = useState(null);

    const [optimizedFormatTemp, setOptimizedFormatTemp] = useState(null);

    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const showProgressOrNot = props.withProgress ? setShowProgress : ()=>{};

    const changeMenuStyleTemp = useRef();

    useInterval(() => {
        if (refreshStyle != null){
            setMenuStyleTemp(refreshStyle);
            setRefreshStyle(null);
        }
        else if (changeMenuStyleTemp.current != null){
            setMenuStyleTemp(changeMenuStyleTemp.current);
            changeMenuStyleTemp.current = null;
        }

    }, showMenuStyle && (isIOS() || isSafari()) && (changeMenuStyleTemp.current != null || refreshStyle != null) ? 200 : null);

    let titleRef= "";
    let helpRef = "";
    let helpRef2 = "";
    let defaultWebLink = "";
    let extraAccepts = "";
    let standaloneAccepts = null;
    let addOrReplaceContent = CHECK_MEDIA_CONTENT_REPLACE;  
    switch (props.mediaContentType){
        case MEDIA_TYPE_AVATAR:
            titleRef = "ChooseMyLogo";            
            if (props.storyContentField != null && !props.storyContentField.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){
                defaultWebLink = props.storyContentField;
            }
            break;
        case MEDIA_TYPE_MUSIC:
            titleRef = "ChooseMyMusic";     
            if (props.storyContentField != null && !props.storyContentField.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){
                defaultWebLink = props.storyContentField;
            } 
            standaloneAccepts = "audio/wav, audio/mpeg, audio/mp4";                  
            break;
        case MEDIA_TYPE_STORE_FRONT:
            titleRef = "ChooseMyStoreFront";
            helpRef = "ChooseMyStoreFrontHelp";
            extraAccepts = ",video/mp4, video/quicktime";
            break;
        case MEDIA_TYPE_INSIDE_PREVIEW:
            titleRef = "ChooseMyInside";
            helpRef = "ChooseMyInsideHelp";   
            extraAccepts = ",video/mp4, video/quicktime";
            addOrReplaceContent = CHECK_MEDIA_CONTENT_ADD;                 
            break;
        case MEDIA_TYPE_MENU_PREVIEW:
            titleRef = "ChooseMyPlates";
            helpRef = "ChooseMyPlatesHelp2";
            extraAccepts = ",video/mp4, video/quicktime";
            addOrReplaceContent = CHECK_MEDIA_CONTENT_ADD;
            break;
        case MEDIA_TYPE_DAY_MENU:
            titleRef = "AddMyDayMenuDialog";            
            break;
        case MEDIA_TYPE_MENU_FR:        
            titleRef = "AddMyMenuDialog";
            helpRef = "AddMyMenuHelp";
            extraAccepts = ",application/pdf";
            if (props.storyContentField != null && !props.storyContentField.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){
                defaultWebLink = props.storyContentField;
            }
            break; 
        case MEDIA_TYPE_MENU_EN:    
            titleRef = "AddMyMenuENDialog";
            helpRef = "AddMyMenuHelp";
            extraAccepts = ",application/pdf";
            if (props.storyContentField != null && !props.storyContentField.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){
                defaultWebLink = props.storyContentField;
            }
            break;                    
    }

    function showContentPickerDialog(){
        if (props.showContentPickerDialog){                           
            
            function showProgressDialog(){                
                if (showProgress){
                    return (
                        <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 16, paddingBottom: 32}}>                       
                            <CircularProgress sx={{color: '#2FC4B5', }}/>
                        </DialogContent>
                    );
                }else return null;
            }

            function showErrorMsg(){
                if (dialogErrorMsg != null){
                    return (
                        <div style={{color: 'red', textAlign: 'center', fontSize: 14, }}><br/>{dialogErrorMsg}</div>
                    );
                }
                else return null;
            }

            function validateUrl() {    
                const hadAMenuSet = props.story.menuFileEn != null || props.story.menuFile != null;            
                const url = document.getElementById("webLink").value;
                if (url.includes("javascript:") || url.includes("<script>") || !url.startsWith("http"))
                    return;
                const fileFormat = getFileFormat(getFileNameFromUrl(url));
                if (props.mediaContentType === MEDIA_TYPE_AVATAR && ! isImg(fileFormat)){
                    setDialogErrorMsg(t('ErrorImageFormat'));
                    return;
                }
                if (props.mediaContentType === MEDIA_TYPE_MUSIC && ! isAudio(fileFormat)){
                    setDialogErrorMsg(t('ErrorAudioFormat'));
                    return;
                }

                props.onSuccess(url, "web_link", fileFormat == null ? "web_link" : fileFormat === "pdf" ? "pdf" : isImg(fileFormat) ? "photo" : isVideo(fileFormat) ? "video" : "web_link");
                closeContentPickerDialog();
                if ((props.mediaContentType === MEDIA_TYPE_MENU_FR && !props.story.menuFileEn || props.mediaContentType === MEDIA_TYPE_MENU_EN && !props.story.menuFile) && fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous) && !hadAMenuSet){
                    validateMenuShowStyle();
                } 
            }                               

            function showFromInstagram(){
                let fromInstagram = null;

                let title = null;
                let filled = false;
                switch (props.mediaContentType){
                    case MEDIA_TYPE_AVATAR:                        
                        if (fbResponse != null && (fbResponse.isAnonymous || fbResponse.providerData[0].providerId === "facebook.com")){
                            title = t('ChooseMyLogo');
                            filled = "instagram";
                        }
                        break;
                    case MEDIA_TYPE_STORE_FRONT:
                        title = t('ChooseMyStoreFront');
                        break;
                    case MEDIA_TYPE_INSIDE_PREVIEW:
                        title = t('ChooseMyInside');
                        filled = "instagram";
                        break;
                    case MEDIA_TYPE_MENU_PREVIEW:
                        title = t('ChooseMyPlates');
                        filled = "instagram";
                        break;
                    case MEDIA_TYPE_MENU_FR:
                        // nothing, menu is not possible from instagram
                        break; 
                    case MEDIA_TYPE_MENU_EN:
                        // nothing, menu is not possible from instagram
                        break; 
                    case MEDIA_TYPE_DAY_MENU:
                        title = t('AddMyDayMenu');
                        break;                                      
                }
                if (title != null && ! (isAndroid() && isFirefox()))
                    fromInstagram = <LoginDialog origin={"content_picker_" + props.mediaContentType} filled={filled} title={title} buttonText={t('FromInstagram')} loginForContent={true} mediaContentType={props.mediaContentType} bypassFromIG={props.bypassFromIG} resetBypassFromIg={props.resetBypassFromIg} onPageSelected={onIGAcountSelected}/>;

                return fromInstagram;
            }

            function showFromWeb(){
                let fromWeb = null;
                switch (props.mediaContentType){
                    case MEDIA_TYPE_AVATAR:
                    case MEDIA_TYPE_MUSIC:
                    case MEDIA_TYPE_MENU_FR:
                    case MEDIA_TYPE_MENU_EN:
                        fromWeb = <div style={{textAlign: 'center'}}>
                            {t('FromTheWeb')}
                            <LineBreak/>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><TextField id="webLink" type="text" defaultValue={defaultWebLink} placeholder={t('PasteWebLink')}/><MaterialButton reducedPadding={true} style="text" onClick={()=>{document.getElementById('webLink').value="";setDialogErrorMsg(null);}} value="X"/><MaterialButton reducedPadding={true} style="text" onClick={()=>validateUrl()} value={t('ok')}/></div>
                        </div>
                    break;                    
                }

                return fromWeb;
            }      
            
            function showFromMyCamera(){
                if (isTabletOrMobile && (props.mediaContentType === MEDIA_TYPE_STORE_FRONT || props.mediaContentType === MEDIA_TYPE_MENU_PREVIEW || props.mediaContentType === MEDIA_TYPE_INSIDE_PREVIEW)){
                    return (<>                            
                            <input id="upload-video-content" name="upload-video-content" accept={"video/*"} capture="environment" style={{display: 'none'}} type='file' onChange={(e)=>uploadContent(e.target.files[0], "camera", "video")}/>
                            <MaterialButton style="outlined-tonal" value={t('FromMyCamera')} onClick={()=>document.getElementById('upload-video-content').click()}/>
                            <LineBreak/>{t('Or')}<LineBreak/>
                        </>
                    );
                }
                else return null;
            }

            return(
                <Dialog onClose={closeLoginFromClickOutside} open={props.showContentPickerDialog != null && props.showContentPickerDialog !== false}>
                <DialogTitle style={{userSelect: 'none', fontSize: 24}}>{t(titleRef)}<br/><span style={{fontStyle: 'italic', fontSize: 14}}>{t(helpRef)}</span>{helpRef2 !== "" && (<><br/><span style={{fontStyle: 'italic', fontSize: 14}}>{t(helpRef2)}</span>)</>)}</DialogTitle>  
                {!showProgress && <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", padding: 16}}>
                    {showFromMyCamera()}
                    {showFromInstagram()}
                    {showFromInstagram() != null && (<><LineBreak/>{t('Or')}{showFromWeb() != null && <LineBreak />}</>)}
                    {showFromWeb()}
                    {showFromWeb() != null && (<><LineBreak/>{t('Or')}</>)}                    
                    <div style={{marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: "center"}}>
                        {isTabletOrMobile ? t('FromMyFiles') : t('FromMyComputer')}
                        <div style={{marginTop: 10}} />
                        <input id="upload-content" name="upload-content" accept={standaloneAccepts ? standaloneAccepts : ("image/jpeg, image/gif,image/png,image/webp,image/svg+xml,image/bmp" + extraAccepts)} style={{display: 'none'}} type='file' onChange={(e)=>{uploadContent(e.target.files[0], "file_system", isImg(getFileFormat(e.target.files[0].name)) ? "photo" : isVideo(getFileFormat(e.target.files[0].name)) ? "video" : getFileFormat(e.target.files[0].name) === "pdf" ? "pdf" : "other");}}/>
                        <MaterialButton style="outlined-tonal" value={t('ChooseAFile')} onClick={()=>document.getElementById('upload-content').click()}/>
                    </div>
                    {showErrorMsg()}                    
                </div> 
                }
                {showProgressDialog()}
            </Dialog>
            );
        }
        else return null;
    }

    function uploadContent(inputFile, source, type){     
        const hadAMenuSet = props.story.menuFileEn != null || props.story.menuFile != null;       
        checkMediaContent(inputFile, story, storyId, baseStory, props.mediaContentType, showProgressOrNot, addOrReplaceContent)                   
            .then(()=>uploadMediaContent(story, storyId, inputFile, props.mediaContentType, source))            
            .then((url)=>{props.onSuccess(url, source, type);closeContentPickerDialog(); return url;})            
            .then((url) => {
                if ((props.mediaContentType === MEDIA_TYPE_MENU_FR && !props.story.menuFileEn || props.mediaContentType === MEDIA_TYPE_MENU_EN && !props.story.menuFile) && fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous) && !hadAMenuSet){
                    validateMenuShowStyle();
                } 
                return url;           
            })
            .catch((error)=>{                                
                Sentry.captureMessage(printObj(error));
                try {
                    setDialogErrorMsg(t(JSON.parse(error.message).error, JSON.parse(error.message).option));
                }
                catch (e){
                    setDialogErrorMsg(t('ErrorDefault'));
                    Sentry.captureMessage(printObj(e));
                }
            })
            .finally(()=>{
                setShowProgress(false);
            });
    } 

    function closeLoginFromClickOutside(){
        analyticsManager.logOpenPopupTag(storyId, "content_picker_"+props.mediaContentType, false);
        closeContentPickerDialog();
    }

    function closeContentPickerDialog(){
        props.setShowContentPickerDialog(false);
        setDialogErrorMsg(null);
        setShowImagePicker(false);         
        setSelectedContentsTemp([]);                
        setPreviousPage(null);
        setNextPage(null);   
        setShowProgress(false);     
    }

    function validateMenuShowStyle(){        
        setMenuStyleTemp(story.menuStyle);
        setMenuColorTemp(props.optimizedFormat ? story.menuColor : story.primaryColor);
        setOptimizedFormatTemp(props.optimizedFormat);
        setShowMenuStyle(true); 
        analyticsManager.logOpenPopupTag(storyId, props.optimizedFormat ? "menu_style" : "cover_style", true);                           
    }

    useEffect(() => {
        if (props.showMenuStyle){
            validateMenuShowStyle();
            props.setShowMenuStyle(false);
        }

    }, [props.showMenuStyle]);    

    function showMenuStyleDialog(){
        async function close(){
            analyticsManager.logOpenPopupTag(storyId, optimizedFormatTemp ? "menu_style" : "cover_style", false);
            setShowMenuStyle(false);
            setMenuStyleTemp(story.menuStyle);
            setMenuColorTemp(story.menuColor);             
            if (story.menuReformatDate == null){
                props.setOptimizedFormat(false);
            }           
        }

        async function confirm(){
            analyticsManager.logChooseStyleTag(storyId, optimizedFormatTemp ? menuStyleTemp : null, menuColorTemp);
            setShowMenuStyle(false);
            setMenuStyleTemp(menuStyleTemp);
            setMenuColorTemp(menuColorTemp);
            updateColor();            

            const db = getFirestore();
            const docRef = doc(db, "stories", storyId); 
            const docSnap = await getDoc(docRef);
            if (optimizedFormatTemp){ 
                analyticsManager.logOptimizeMenuTag(storyId, true);                               
                if (docSnap.data().menuReformatDate == null) {                     
                    await updateDoc(docRef, {        
                        menuReformatDate: new Date().getTime(),
                        menuOptimizedFormat: true,
                        savedTime: new Date().getTime(),
                    });
                }      
                if (! menuEmailSent){
                    sendMenuOptimizedEmail(storyId, story.posName);
                    setMenuEmailSent(true);
                }          
            }
            else {
                analyticsManager.logOptimizeMenuTag(storyId, false);                
                await updateDoc(docRef, {        
                    menuReformatDate: deleteField(),
                    menuOptimizedFormat: deleteField(),
                    savedTime: new Date().getTime(),
                });                
            }

            if (optimizedFormatTemp != story.menuOptimizedFormat || menuStyleTemp != story.menuStyle || menuColorTemp != story.menuColor){
                setTimeout(()=>setHighlight("menu"), 1000);
                setFeatureReset(true);
            }
        }

        async function updateColor(){            
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);    
            const update = {
                menuStyle: menuStyleTemp,
                menuColor: menuColorTemp,  
                savedTime: new Date().getTime(),              
            };
    
            await updateDoc(docRef, update);
        }    

        if (showMenuStyle){
            const label = { inputProps: { 'aria-label': 'TODO' } };

            return(
                <Dialog onClose={close} open={showMenuStyle}>
                    <DialogTitle style={{userSelect: 'none', fontSize: 24}}>{props.optimizedFormat ? t('ChooseYourMenuStyle') : t('ChooseMyCover')}</DialogTitle>  
                    <div style={{marginTop: 16, display: 'flex', flexDirection: "row", alignItems: 'center', paddingLeft: 24, paddingRight: 24,}}>
                        {fbResponse != null && <>
                            <div style={{fontSize: 20}}>
                                {t("OptimMyMenu")}
                                {fbResponse != null && !fbResponse.isAnonymous && !optimizedFormatTemp && <div style={{marginTop: 6, fontSize: 14, fontStyle: 'italic'}}>{t("OptimMyMenuHelp")}</div>}
                                {fbResponse != null && !fbResponse.isAnonymous && optimizedFormatTemp && story.menuReformatDate && <div style={{marginTop: 6,fontSize: 14, fontStyle: 'italic'}}>{t('OptimStatus', {date: new Date(story.menuReformatDate).toLocaleString(browserLocales(), new Date().getTimezoneOffset())})}</div>}                                       
                            </div>                 
                            <div style={{marginLeft: 40}}/>
                            <Switch {...label} defaultChecked={props.optimizedFormat} checked={optimizedFormatTemp} onChange={(e)=>
                            {
                                if (e.target.checked){                                                                         
                                    setOptimizedFormatTemp(true);                                    
                                }
                                else {                                    
                                    setOptimizedFormatTemp(false);                                                                                                            
                                }
                            }} />
                        </>}                                                                                                                                            
                    </div>                 
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: "flex-start", padding: 24, }}>                                                 
                        <div style={{fontSize: 20}}>{optimizedFormatTemp ? t('MenuStyle') : t('CoverStyle')}</div>
                        {!optimizedFormatTemp && <div style={{marginTop: 6, fontSize: 14, fontStyle: 'italic'}}>{t("MenuStyleHelp")}</div>}
                        <LineBreak/>
                            <select id="menu-style" value={menuStyleTemp} onChange={(e)=>setMenuStyleTemp(e.target.value)}>
                                <option value="simple">{t('Simple')}</option>
                                <option value="modern">{t('Modern')}</option>
                                <option value="elegant">{t('Elegant')}</option>                            
                                <option value="friendly">{t('Friendly')}</option>                            
                                <option value="custom">{t('Custom')}</option>
                            </select>
                        {!optimizedFormatTemp && 
                            <>
                                <LineBreak/>
                                <LineBreak/>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: "center", width: '100%'}}>                                    
                                    <MenuStylePreview menuStyleTemp={menuStyleTemp} menuColorTemp={menuColorTemp} multiplier={1} optimizedFormat={optimizedFormatTemp}/>
                                </div>
                            </>
                        }                
                        {optimizedFormatTemp && 
                            <>
                                {menuStyleTemp !== "custom" &&
                                    <>
                                        <LineBreak/>
                                        <div style={{fontSize: 20}}>{t('ChooseYourMenuColor')}</div>                                                                
                                        <LineBreak/>
                                        <ColorPicker id="menuColor" color={menuColorTemp} type="menuColor" handleChange={(color)=>{
                                            setMenuColorTemp(color.hex);
                                            changeMenuStyleTemp.current = menuStyleTemp;
                                            if (menuStyleTemp === "simple"){
                                                setRefreshStyle("elegant");                    
                                            }
                                            else {
                                                setRefreshStyle("simple");
                                            } 
                                            }}/> 
                                                             
                                        <LineBreak/>
                                        <i>{t("MenuPreview")}</i>
                                        <LineBreak/>
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: "center", width: '100%'}}>                                    
                                            <MenuStylePreview menuStyleTemp={menuStyleTemp} menuColorTemp={menuColorTemp} multiplier={1} optimizedFormat={optimizedFormatTemp}/>
                                        </div>
                                        </>   
                                }
                                {menuStyleTemp === "custom" && !story.menuId &&
                                    <>
                                        <LineBreak/>
                                        {t('ChooseYourMenuCustom')}
                                    </>
                                }  
                                {menuStyleTemp === "custom" && story.menuId &&
                                    <>
                                        <LineBreak/>
                                        {t('ChooseYourMenuCustomAlreadyFormated')}
                                    </>
                                }    
                                <LineBreak/><LineBreak/>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div style={{fontSize: 20}}>{t("DishImages")}</div><div style={{fontStyle: 'italic', marginLeft: 4, marginTop: 2}}>{t("Optional")}</div></div>
                                <LineBreak/>
                                <MaterialButton icon="/images/ic_wetransfer.png" style="wetransfer" onClick={()=>{analyticsManager.logClickWeTransferTag(storyId, "menu_style");window.open("https://wetransfer.com/?to=jacques@upreality.store", "_blank");}} value={t("SendWithWeTransfer")} />
                                <LineBreak/><LineBreak/>
                                <i>
                                    {t('ChooseStyleValidationHelp1')}<br/>
                                    {t('ChooseStyleValidationHelp2')}
                                </i>
                            </>    
                        }
                        <div style={{marginTop: 40, width: '100%', display: 'flex', flexDirection: 'row', alignItems: "flex-end", alignContent: 'flex-end', justifyContent: 'flex-end'}}>
                            <MaterialButton style="outlined-tonal" onClick={close} value={t("Cancel")} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<MaterialButton style="filled" onClick={confirm} value={t("Confirm")} />
                        </div>
                    </div>                     
                </Dialog>
            );
        } else return null;
    }

    function onIGAcountSelected(igAccountId, igLoginType){       
        if (igAccountId instanceof Error){                            
            setDialogErrorMsg(t(JSON.parse(igAccountId.message).error, JSON.parse(igAccountId.message).option));
        }
        else if (props.mediaContentType === MEDIA_TYPE_AVATAR && igLoginType === 'fb')
            getAvatarFromInstagram(fbAccessToken, igAccountId)
                .then((profile_picture_url)=>{
                    return getFileFromUrl(profile_picture_url)
                })
                .catch((error)=>{  
                    // TODO do not reset FB/IG things if the error comes from the getFileFromUrl
                    setFbAccessToken(null);
                    setInstagramAccountId(null);   
                    setInstagramLoginType(null);     
                    Sentry.captureMessage(printObj(error));              
                    try {
                        setDialogErrorMsg(t(JSON.parse(error.message).error, JSON.parse(error.message).option));
                    }
                    catch (e){
                        setDialogErrorMsg(t('ErrorDefault'));
                        Sentry.captureMessage(printObj(e));
                    }
                });
        else if (igLoginType === 'fb')           
            getMediaContentsFromInstagram(fbAccessToken, igAccountId)  
                .then((response)=>{
                    if (response.data == null || response.data.length === 0){
                        throw new Error(JSON.stringify({error: "NoMediaFound"}));
                    }
                    setIgPictures(response.data);
                    if (response.paging != null){
                        setPreviousPage(response.paging.previous);                    
                        setNextPage(response.paging.next);
                    }
                    setShowImagePicker(props.mediaContentType);
                    analyticsManager.logOpenPopupTag(storyId, "content_picker_instagram_"+props.mediaContentType, true);
                })
                .catch((error)=>{
                    setFbAccessToken(null);
                    setInstagramAccountId(null);
                    setInstagramLoginType(null);
                    Sentry.captureMessage(printObj(error));
                    try {
                        setDialogErrorMsg(t(JSON.parse(error.message).error, JSON.parse(error.message).option));
                    }
                    catch (e){
                        Sentry.captureMessage(printObj(e));
                        setDialogErrorMsg(t('ErrorDefault'));
                    }
                }); 
        else if (igLoginType === 'ig')
            fetch("https://graph.instagram.com/" + igAccountId + "/media?fields=id,media_type,media_url,thumbnail_url&access_token=" + fbAccessToken)
                .then((response) => response.json())
                .then((response)=>{   
                    if (response.data == null || response.data.length === 0){
                        throw new Error(JSON.stringify({error: "NoMediaFound"}));
                    }
                    setIgPictures(response.data);
                    if (response.paging != null){
                        setPreviousPage(response.paging.previous);                    
                        setNextPage(response.paging.next);
                    }
                    setShowImagePicker(props.mediaContentType);
                    analyticsManager.logOpenPopupTag(storyId, "content_picker_instagram_"+props.mediaContentType, true);
                })
                .catch((error)=>{                           
                    setFbAccessToken(null);
                    setInstagramAccountId(null);
                    setInstagramLoginType(null);
                    Sentry.captureMessage(error);
                    try {
                        setDialogErrorMsg(t(JSON.parse(error.message).error, JSON.parse(error.message).option));
                    }
                    catch (e){
                        Sentry.captureMessage(printObj(e));
                        setDialogErrorMsg(t('ErrorDefault'));
                    }
                }); 
    }

    function getFileFromUrl(mediaUrl){ 
        setShowProgress(true);          
        return fetch(mediaUrl)
            .then(res => res.blob()) // Gets the response and returns it as a blob
            .then(blob => {                                       
               //blob.name = props.mediaContentType + "-" + new Date().getTime() + "-" + props.mediaContentType + "." + getFileExtensionFromMimeType(blob.type);   
               blob.name = getFileNameFromUrl(mediaUrl);
               const imgOverVideo = isImg(getFileExtensionFromMimeType(blob.type));
               return {blob: blob, imgOverVideo: imgOverVideo}                  
            })
            .then((response)=>{
                uploadContent(response.blob, "instagram", response.imgOverVideo ? "photo" : "video"); 
            })
            .then(()=>{
                return mediaUrl;
            });
    }

    function showImagePickerDialog(){
        function closeByIntent(){
            close();
            analyticsManager.logOpenPopupTag(storyId, "content_picker_instagram_"+props.mediaContentType, false);
        } 
        
        function close(){
            setShowImagePicker(false);
            setDialogErrorMsg(null);
            setSelectedContentsTemp([]);
            setPreviousPage(null);
            setNextPage(null);            
        } 

        function showProgressDialog(){                
            if (showProgress){
                return (
                    <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 16, paddingBottom: 32}}>                       
                        <CircularProgress sx={{color: '#2FC4B5', }}/>
                    </DialogContent>
                );
            }else return null;
        }

        function showErrorMsg(){
            if (dialogErrorMsg != null){
                return (
                    <div style={{color: 'red', textAlign: 'center', fontSize: 14, marginBottom: 16}}><br/>{dialogErrorMsg}</div>
                );
            }
            else return null;
        }

        function showPageBtns(){

            function onPreviousClick(){
                analyticsManager.logPreviousPageTag(storyId, "content_picker_" + props.mediaContentType);
                fetch(previousPage)
                    .then(response => response.json())
                    .then((response)=>{
                        setIgPictures(response.data);
                        setPreviousPage(response.paging.previous);
                        setNextPage(response.paging.next);
                });
            }

            function onNextClick(){       
                analyticsManager.logNextPageTag(storyId, "content_picker_" + props.mediaContentType);       
                fetch(nextPage)
                    .then(response => response.json())
                    .then((response)=>{                   
                        setIgPictures(response.data);
                        setPreviousPage(response.paging.previous);
                        setNextPage(response.paging.next);
                    });
            }

            function showPreviousButton(){
                if (previousPage != null){
                    return (
                        <div style={{visibility: showProgress ? 'hidden' : 'visible'}}><MaterialButton style="text" onClick={onPreviousClick} value={(<>&nbsp;&nbsp;&nbsp;{t("PreviousPage")}</>)}/></div>
                    );
                }
                else return null;
            }

            function showNextButton(){
                if (nextPage != null){
                    return (
                        <div style={{visibility: showProgress ? 'hidden' : 'visible'}}><MaterialButton style="text" onClick={onNextClick} value={(<>{t("NextPage")}&nbsp;&nbsp;&nbsp;</>)} /></div>
                    );
                }
                else return null;
            }

            if (previousPage != null || nextPage != null){
                const justifyStyle = {};
                if (previousPage != null && nextPage != null)
                    justifyStyle.justifyContent = 'space-between';
                else if (previousPage != null)
                    justifyStyle.justifyContent = 'flex-start';
                else if (nextPage != null)
                    justifyStyle.justifyContent = 'flex-end';
                return (
                    <div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', ...justifyStyle}}>
                            {showPreviousButton()}{showNextButton()}
                        </div>
                    </div>
                );
            } else return null;
        }

        function showValidateBtns(){
            if (showImagePicker === MEDIA_TYPE_INSIDE_PREVIEW || showImagePicker === MEDIA_TYPE_MENU_PREVIEW){
                return (
                    <div style={{visibility: showProgress ? 'hidden' : 'visible'}}>
                        <br/>
                        <br/>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: "flex-end", alignContent: 'flex-end', justifyContent: 'flex-end', marginBottom: 16}}>
                            <MaterialButton style="outlined-tonal" onClick={()=>{closeByIntent();}} value={t("Cancel")}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<MaterialButton style="filled" value={t("Confirm")} onClick={()=>{validateSelection()}}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                );
            } else return null;
        }

        async function validateSelection(){                        
            setShowProgress(true);
            for (let i = 0; i < selectedContentsTemp.length; i++){                
                fetch(selectedContentsTemp[i])
                    .then(res => res.blob()) // Gets the response and returns it as a blob
                    .then(blob => {   
                        // TODO a 2nd timestamp is added on uploadMediaContent => save file name in selectedContentsTemp                             
                        blob.name = new Date().getTime() + "." + getFileExtensionFromMimeType(blob.type);                                  
                            
                        checkMediaContent(blob, story, storyId, baseStory, props.mediaContentType, ()=>{}, CHECK_MEDIA_CONTENT_ADD)                    
                            .then(() => uploadMediaContent(story, storyId, blob, props.mediaContentType, "instagram"))
                            .then( (url)=>{         
                                props.onSuccess(url, "instagram", isImg(getFileExtensionFromMimeType(blob.type)) ? "photo" : "video");

                                setDialogErrorMsg(null);})
                            .catch((error)=>{
                                Sentry.captureMessage(printObj(error));
                                try {
                                    setDialogErrorMsg(t(JSON.parse(error.message).error, JSON.parse(error.message).option));
                                }
                                catch (e){
                                    Sentry.captureMessage(printObj(e));
                                    setDialogErrorMsg(t('ErrorDefault'));
                                }
                            })
                            .finally(()=>{
                                if (i === selectedContentsTemp.length - 1){
                                    setShowProgress(false);
                                }
                            });
                    });
                if (i !== selectedContentsTemp.length - 1){                         
                    await sleep(500);
                }
            }

            props.setSelectedContents(selectedContentsTemp);
            props.setShowContentPickerDialog(false);

            close();            
        }            

        const imageWidth = isTabletOrMobile ? 90 : 112;
        const imageHeight = isTabletOrMobile ? 160 : 200;

        if (showImagePicker != null && showImagePicker != false){
            const picturesList = igPictures.filter((picture) => picture.media_type === 'IMAGE' || showImagePicker === MEDIA_TYPE_MENU_PREVIEW && picture.media_type === 'VIDEO' || showImagePicker === MEDIA_TYPE_INSIDE_PREVIEW && picture.media_type === 'VIDEO' || showImagePicker === MEDIA_TYPE_STORE_FRONT && picture.media_type === 'VIDEO').map((picture) => {
                
                const url = picture.media_url;
                const fileFormat = getFileFormat(getFileNameFromUrl(url));
                const disabled = ! isSupportedByPlayer(fileFormat);

                // default single selection
                let onImgClick = (e) => {   
                    if (disabled)
                        return;                 
                    getFileFromUrl(url)
                        .catch((error)=>{
                            Sentry.captureMessage(printObj(error));
                            try {
                                setDialogErrorMsg(t(JSON.parse(error.message).error, JSON.parse(error.message).option));
                            }
                            catch (e){
                                Sentry.captureMessage(printObj(e));
                                setDialogErrorMsg(t('ErrorDefault'));
                            }
                        });
                };
                // multiple selection
                if (showImagePicker === MEDIA_TYPE_INSIDE_PREVIEW || showImagePicker === MEDIA_TYPE_MENU_PREVIEW){
                    onImgClick = (e) => {
                        if (disabled)
                            return;

                        if (Array.from(selectedContentsTemp).includes(picture.media_url)){
                            const newSelectedContents = Array.from(selectedContentsTemp);
                            newSelectedContents.splice(newSelectedContents.indexOf(picture.media_url), 1);                            
                            setSelectedContentsTemp(newSelectedContents);
                        }                            
                        else{                            
                            if (showImagePicker === MEDIA_TYPE_INSIDE_PREVIEW && ((story.tourMediasRef != null ? story.tourMediasRef.length : 0) + Array.from(selectedContentsTemp).length) >= 2)
                                return;
                            if (showImagePicker === MEDIA_TYPE_MENU_PREVIEW && ((story.mediasRef != null ? story.mediasRef.length : 0) + Array.from(selectedContentsTemp).length) >= 4)
                                return;                            
                            const newSelectedContents = Array.from(selectedContentsTemp);
                            newSelectedContents.push(picture.media_url);                            
                            setSelectedContentsTemp(newSelectedContents);                                                    
                        }
                    };
                }                

                function showVideoIndicator(picture){
                    if (picture.media_type === 'VIDEO'){                        
                        return (                            
                            <Image style={{position: 'absolute', right: 8, bottom: 8, width: 24, height: 24}} tintColor='#FFFFFF' source="/images/ic_video.png"/>                       
                        );
                    }
                    else return null;
                }

                const selectedStyle = {};
                if (Array.from(selectedContentsTemp).includes(picture.media_url)){
                    selectedStyle.boxShadow = "0px 0px 0px 4px #2FC4B580";                    
                }
                else {
                    selectedStyle.boxShadow = "none";
                }

                //log("picture draw " + picture.media_url);
                return (
                    <div key={picture.id} style={{margin: 8, position: 'relative', width: imageWidth, height: imageHeight, }}>
                        <img alt="this content is a video" id={picture.media_url} style={{width: imageWidth, height: imageHeight, position: 'absolute', objectFit: 'cover', cursor: 'pointer', ...selectedStyle}} src={picture.media_type === 'IMAGE' ? picture.media_url : picture.thumbnail_url} onClick={(e)=>onImgClick(e)}/>
                        {showVideoIndicator(picture)}
                    </div>
                );
            });

            return (
                <Dialog onClose={closeByIntent} open={showImagePicker != null && showImagePicker !== false} maxWidth={isTabletOrMobile ? windowDimensions.width - 80 : 528} fullWidth={isTabletOrMobile}>
                    <DialogTitle style={{userSelect: 'none', fontSize: 24}}>{t(titleRef)}<br/><span style={{fontStyle: 'italic', fontSize: 14}}>{t(helpRef)}</span>{helpRef2 !== "" && (<><br/><span style={{fontStyle: 'italic', fontSize: 14}}>{t(helpRef2)}</span>)</>)}</DialogTitle>  
                    {!showProgress && <div style={{display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: 'center', alignContent: 'flex-start', flexWrap: 'wrap', padding: 8,}}>
                        {picturesList}                        
                    </div>}
                    {showErrorMsg()}
                    {showPageBtns()}
                    {showValidateBtns()}
                    {showProgressDialog()}
                </Dialog>
            );              
        }
        else return null;
    } 

    return (
        <>
            {showContentPickerDialog()}
            {showMenuStyleDialog()}            
            {showImagePickerDialog()}
        </>
    );    
}

export default ContentPickerDialog;