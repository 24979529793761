import React,{ useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import LineBreak from './LineBreak';
import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { doc, getFirestore, updateDoc, deleteField } from 'firebase/firestore';
import { MakerContext } from '../MakerContext';
import { analyticsManager } from '../..';

function PixelDialog(props){
    const { t } = useTranslation();
    const { onClose, opened } = props;     
    const storyId = useContext(MakerContext).get("storyId");
    const story = useContext(MakerContext).get("story");
    const [metaPixel, setMetaPixel] = useState(story.metaPixel);
    const [tiktokPixel, setTikTokPixel] = useState(story.tiktokPixel);


    async function setPixels(){                  
        const update = {            
            savedTime: new Date().getTime(),
        };    
        const metaPixelInput = document.getElementById("metaPixelInput");
        const tiktokPixelInput = document.getElementById("tiktokPixelInput");  
        if (metaPixelInput.value.trim() !== ""){
            update.metaPixel = metaPixelInput.value;            
        }
        else {
            update.metaPixel = deleteField();
        }

        if (tiktokPixelInput.value.trim() !== ""){
            update.tiktokPixel = tiktokPixelInput.value;            
        } 
        else {
            update.tiktokPixel = deleteField();
        }               
        
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        await updateDoc(docRef, update);  
        if (metaPixelInput.value.trim() !== ""){
            setMetaPixel(update.metaPixel);
        }
        else {
            setMetaPixel("");
        }
        if (tiktokPixelInput.value.trim() !== ""){
            setTikTokPixel(update.tiktokPixel);
        } 
        else {
            setTikTokPixel("");
        }
        if (metaPixelInput.value.trim() !== "" || tiktokPixelInput.value.trim() !== ""){
            analyticsManager.logConfigurePixelsTag(storyId, true);
        }
        else {
            analyticsManager.logOpenPopupTag(storyId, "configure_pixels", false);
        }             
    }

    return (<>                
                <Dialog open={opened} onClose={()=>{analyticsManager.logOpenPopupTag(storyId, "configure_pixels", false);onClose();}}>
                    <div onClick={(e)=>e.stopPropagation()}>
                        <DialogTitle style={{ userSelect: 'none', WebkitUserSelect: 'none', fontSize: 24,  }}>{t("ConfigureMyPixelsDialog")}</DialogTitle>
                            <DialogContent>
                                <i>{t("ConfigureMyPixelsHelp")}</i>
                                <LineBreak/>
                                <Box>
                                <TextField id='metaPixelInput' label={t('Meta')} variant="standard" type='text' style={{width: "100%"}} defaultValue={metaPixel} />
                                <LineBreak/>
                                <TextField id='tiktokPixelInput' label={t('TikTok')} variant="standard" type='text' style={{width: "100%"}} defaultValue={tiktokPixel} />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "configure_pixels", false);onClose();}}>
                                    {t('Cancel')}
                                </Button>
                                <Button onClick={()=>{setPixels();onClose();}}>
                                    {t('OK')}
                                </Button>
                            </DialogActions>
                    </div>
                </Dialog>                  
        </>      
    );
}

export default PixelDialog;